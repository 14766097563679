.table-data {
    border-radius: 20px;
    margin-left: 5%;
    margin-right: 10%;
    overflow: auto;
    max-height: 75vh;

    .MuiTable-root {
        border-radius: 20px;
        background-color: #ffffff;
    }

    .MuiTableHead-root {
        .MuiTableRow-root {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: #c5c5c5;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
        }
    }

    .title-row {
        cursor: pointer;
    }

    .cluster-btn {
        pointer-events: auto;
        display: flex;
        justify-content: center;
    }


}