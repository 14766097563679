$afternoon: orange;
$morning: #00ddff;
$allday: #51c56a;
$alpha: 0.6;
.registration-container {
    width: 100%;
    font-family: "Poppins", sans-serif !important;
    padding-bottom: 16px;
    > .title {
        margin-top: 12px;
        text-align: center;
        font-size: 24px;
        padding: 12px;
        background-color: #dc3545;
        color: #fff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        &.holiday {
            background-color: #2d69df;
        }
        &.complementDay {
            background-color: #a8a325;
        }
    }

    .tile-icon {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background: #fff;
        justify-content: center;
    }
    .registration-0 {
        width: 100%;
        * {
            color: #212121;
        }
        .selecting-date-boundary {
            border-radius: 8px;
            border: 2px solid #ddd;
            padding: 8px;
            margin-bottom: 12px;
            .title {
                font-weight: 500;
            }
            .selecting-date {
                justify-content: space-between;
                > :nth-child(1) {
                    color: #aaa;
                    > i {
                        color: #212121;
                    }
                }
                > :nth-child(2) {
                    opacity: 0.8;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
            .slot-tag {
                min-height: 24px;
                padding-top: 10px;
                .slot-tag-selected {
                    padding: 4px 8px;
                    border-radius: 6px;
                    font-size: 12px;
                    border: 1px solid;
                    margin-right: 6px;

                    &.registed {
                        &.morning {
                            border-color: $morning;
                        }
                        &.afternoon {
                            border-color: $afternoon;
                        }
                        &.cancel {
                            text-decoration: line-through;
                        }

                        &.urgent_1,
                        &.urgent_2,
                        &.-disabled {
                            border-color: #ccc;
                        }
                    }

                    .--cancel {
                        border-radius: 2px;
                        transition: all 0.2s;
                        cursor: pointer;
                        width: 16px;
                        height: 16px;
                        margin-left: 4px;
                        justify-content: center;

                        &:hover {
                            background-color: #dfdfdf;
                        }
                    }
                }
            }
            .opt-btn {
                padding: 4px;
                border-radius: 6px;
                font-size: 12px;
                border: 2px solid #ccc;
                cursor: pointer;
                width: 100px;
                text-align: center;
                transition: 0.2s all;
                margin-right: 8px;

                &.morning {
                    background-color: rgba($color: $morning, $alpha: $alpha);
                    border-color: $morning;
                }

                &.afternoon {
                    background-color: rgba($color: $afternoon, $alpha: $alpha);
                    border-color: $afternoon;
                }
                &.allday {
                    background-color: rgba($color: $allday, $alpha: $alpha);
                    border-color: $allday;
                }
                &.-disabled {
                    background-color: rgba($color: #ccc, $alpha: $alpha);
                    border-color: #ccc;
                }
                &:hover,
                &.-disabled {
                    opacity: 0.8;
                }
            }
        }

        .reason {
            textarea {
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 4px;
                min-height: 128px;
                &:focus-visible {
                    outline-color: #ccc;
                }
                &::selection {
                    background: #555555;
                }
            }
        }

        .submit {
            background-color: #555555;
            color: #fff;
            padding: 6px;
            border-radius: 4px;
            width: 100px;
            text-align: center;
            float: right;
            // cursor: pointer;
            margin-top: 8px;
        }
    }
}

.-calendar {
    .react-calendar {
        width: 100%;
        .react-calendar__tile {
            position: relative;
            overflow: visible !important;
        }
        .react-calendar__tile--now,
        .react-calendar__tile--now:hover,
        .react-calendar__tile--now:enabled:focus {
            background: #d4d294;
        }
        .react-calendar__tile--active {
            background: none;
            color: inherit;
            &.now {
                background: #d4d294;
            }
            &:hover {
                background-color: inherit;
                &.now {
                    background: #d4d294;
                }
            }
        }

        .registed.dayoff {
            &.morning {
                background: $morning;
            }

            &.afternoon {
                background: $afternoon;
            }

            &.morning.afternoon {
                background-color: $allday;
            }
            &:hover,
            &:focus {
                background-color: rgba(220, 53, 70, 0.4) !important;
            }
        }

        .pending {
            background: rgba(220, 53, 69, 0.4) !important;
            color: #fff;
            &:hover,
            &:focus {
                background-color: rgba(220, 53, 69, 0.4);
            }
        }

        .now,
        .now:hover {
            color: #fff;
        }

        .holiday-icon,
        .complementday-icon {
            position: absolute;
            bottom: 0;
            color: #212121;
            > span {
                display: none;
                background-color: #ececec;
                color: #868686;
                position: absolute;
                padding: 4px;
                border-radius: 4px;
                width: max-content;
                max-width: 256px;
                top: 16px;
                left: 8px;
                z-index: 10;
            }

            &:hover {
                > span {
                    display: inline;
                }
            }

            .icon {
                &.morning {
                    path:nth-child(1) {
                        color: $morning;
                        opacity: 1;
                    }
                }

                &.afternoon {
                    path:nth-child(1) {
                        color: $afternoon;
                        opacity: 1;
                    }
                }

                &.morning.afternoon {
                    path:nth-child(1) {
                        color: $allday;
                        opacity: 1;
                    }
                }
            }
        }
        .holiday-icon {
            left: 0;
        }
        .complementday-icon {
            right: 0;
        }
    }

    .note {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        margin-right: 6px;
        &.-morning {
            background: $morning;
        }
        &.-afternoon {
            background: $afternoon;
        }
        &.-allday {
            background: $allday;
        }
    }
}
.warning {
    background-color: #fff3cd;
    border: 1px solid #ffecb5;
    color: #664d03;
    border-radius: 6px;
    font-size: 14px;
    padding: 8px;
    margin-top: 8px;
    width: 100%;
    p {
        margin-bottom: 0px;
    }
}
