.reports-page {
    > div:first-child {
        > *:not(:first-child) {
            margin-left: 6px;
        }
    }
    .filter-active-users {
        // justify-content: space-between;
        font-size: 12px;
        width: 100%;
        line-height: 12px;
        padding: 6px;
        max-width: 170px;
        height: 38px;
        margin-right: 4px;
        text-align: right;
    }
    .report-chart {
        background-color: #3f51b5;
        color: #fff;
        min-width: 128px;
        text-transform: unset;
        font-size: 18px;
        height: 38px;
        padding: 2px;
        border-radius: 6px;
    }
    .boundary-btn-func {
        margin-top: 10px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #fff;
    }
    .reports-page-content {
        padding-top: 10px;
    }

    .tab-panel {
        .color_teams_reports {
            width: 100%;
            margin-bottom: 10px;
            .tab-item {
                background-color: #eee;
                margin-right: 10px;
                padding: 8px 20px;
                &.active,
                &:hover {
                    background-color: rgb(13, 110, 253);
                    color: white;
                }
            }
        }
        .table {
            tr.color.low {
                background-color: rgb(255, 108, 108);
            }
            tr.color.high {
                background-color: rgb(108, 255, 115);
            }
            td {
                cursor: pointer;
            }
        }
    }
    .table-tasks-done {
        tr {
            td:nth-child(2) {
                width: 300px;
            }
            td:not(:nth-child(2)) {
                text-align: center;
            }
            .avatar {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    margin-right: 5px;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                }
            }
        }
    }
}
.user-progress-content {
    .contribution {
        border-bottom: 1px solid #ddd;
        padding: 8px;
    }
    .more-details {
        padding-left: 70px;
    }
    .item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .right {
            padding-left: 8px;
            width: 100%;
        }
        .title {
            font-weight: 600;
            font-size: 1.2em;
        }
        .details {
            width: 100%;
            background: #ddd;
            padding: 4px 8px;
            border-radius: 30px;
            position: relative;
            .score {
                z-index: 1;
                position: relative;
            }
            .u-progress {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background-color: aquamarine;
                border-radius: 30px;
            }
        }
    }
}
