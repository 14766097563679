.modal-body.create-campaign-modal {
    display: flex;
    flex-flow: row nowrap;
    padding: 20px;

    h5 {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    .left {
        margin-right: 50px;
        max-width: 610px;

        .field {
            margin-bottom: 25px;

            h5 {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }

        .description {
            margin-top: 20px;
            margin-bottom: 50px;
            width: 610px;

            .description-line-1 {
                display: flex;
                flex-direction: row;

                .edit-description-button {
                    margin-left: 10px;
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }

            .edit-description {
                .handle-edit-description-button {
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .show-description {
                width: 610px;
                min-height: 30px;
                word-wrap: break-word;
                display: inline-block;

                .image {
                    text-align: center;
                }
            }

            .show-description:hover {
                width: 610px;
                min-height: 30px;
                word-wrap: break-word;
                background: #ebe9e9;
                border-radius: 5px;
            }

            img {
                max-width: 580px;
                cursor: pointer;
            }
        }
    }

    .right {
        flex: 1;
        margin-right: 20px;

        .field {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            justify-content: space-between;
            align-items: center;

            h5 {
                margin: 0;
            }

            .select-user, .select-project, .select-campaign {
                width: 70%;
            }
        }

        .point {
            position: relative;
            .text-box {
                width: calc(100% - 90px);

                input {
                    width: 100%
                }
            }
            .edit-field-button {
                position: absolute;
                right: 3px;
                top: 3px;
                margin: 0px;

                .close-button,
                .save-button {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 18px
                    }
                }
            }
        }

        .form-select, .form-multi-select {
            max-width: 70%;
            min-width: 70%;

            div {
                white-space: initial;
            }
        }

        .form-control {
            width: 70%;
        }

        .deadline {
            .date {
                margin-left: 70px;
                transform: translateY(5px);
            }
        }
    }

    .wrap-task {
        background: #fff;
        border-radius: 25px;
        margin-top: 10px;
        box-shadow: 0px 2px 2px #cccccc;
        display: block;
        transform: translate3d(0, 0, 0);

        .task-box {
            .task-content .line .line-2 {
                .right {
                    margin-top: 35px;

                    .shortId {
                        display: none;
                    }

                    .wrap-point {
                        display: none;
                    }
                }
            }
        }

        .task-btn {
            text-align: center;
        }
    }
}
