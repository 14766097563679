.main-header {
    display: flex;
    justify-content: space-between;
    margin: 0 20px 28px 80px;
    .left {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #495467;
        }
    }
    .right {
        > select {
            border: none;
            font-size: 14px;
        }
    }
}
