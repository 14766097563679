.releases-page {
    position: relative;
    right: 160px;
    bottom: 0;

    .releases-form {
        position: relative;
    }

    .filters {
        position: absolute;
        top: 40px;
        left: calc(100% + 50px);
        width: 240px;
        display: flex;
        flex-direction: column;
    }

    .filter-form {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 28px;
    }

    .releases-page-content {
        height: 725px;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0px 10px;
        background-color: #fff;
        border-radius: 9px;
        overflow-y: scroll;
        position: relative;

        .table-releases {
            tr {
                td:nth-child(2) {
                    width: 150px;
                }

                td:not(:nth-child(2)) {
                    text-align: center;

                }

                .avatar {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &:hover {
                        cursor: pointer;
                        color: #2196f3
                    }

                    img {
                        margin-right: 5px;
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                    }
                }
            }
        }
    }

    .pagination {
        margin: 20px 0 10px;
        display: flex;
        justify-content: center;
    }
}