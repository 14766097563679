.section-container {
    padding-top: 30px;

    .section-title {
        display: flex;
        align-items: center;

        .title {
            margin-right: 15px;
            margin-bottom: 0;
        }

        .number-tests{
            border: 1px solid lightblue;
            padding: 1px 8px;
            border-radius: 50%;
            background-color: rgb(2, 98, 130);
            color: white;
            margin-right: 10px;
        }
    }

    .section-content{
        .table-header{
            th{
                background-color: #e9e9e9;
                font-weight: 600;
            }
        }

        .table-body {
            img {
                max-width: 100%;
            }
        }
    }
}