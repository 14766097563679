.item-certificate {
    .item-certificate-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .group-start {
            padding: 20px 28px;
            display: flex;
            gap: 10px;
            align-items: center;
            width: 100%;
            cursor: pointer;
            p {
                margin: 0;
            }
        }
        .icon-choose {
            cursor: pointer;
        }
    }
}
#menu-choose {
    display: flex;

    .MuiPaper-root {
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(73, 84, 103, 0.12);
        border-radius: 8px;
        transform: translateX(-115px) !important;
        .MuiMenuItem-root {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 8px 16px;
            justify-content: flex-start;
            p {
                margin: 0;
            }
        }
    }
}
