.projects-filter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    // margin-left: 20px;
    // margin-bottom: 20px;

    .project {
        .wrap-active {
            background-color: #fff;
            box-shadow: 0px 3px 0px #08adff;
            border-radius: 15px;
            padding-bottom: 1px;
            margin: 10px;
            min-width: 196px;
            display: block;
            cursor: pointer;

            p {
                text-align: center;
                transform: translateY(7px);
            }
        }

        .wrap {
            background-color: #fff;
            box-shadow: 0px 2px 2px #cccccc;
            border-radius: 15px;
            padding-bottom: 1px;
            margin: 10px;
            min-width: 196px;
            display: block;
            cursor: pointer;

            p {
                text-align: center;
                transform: translateY(7px);
            }
        }
    }
}

.projects-filter-v2 {
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    width: 100%;

    > span {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
    .project-v2 {
        margin-top: 16px;
        height: 64px;
        font-size: 12px;
        cursor: pointer;
        transition: all 0.2s;
        flex-direction: column;
        &.wrap-active {
            border: 1px solid #08adff;
        }

        &.wrap {
            border: none;
        }
    }
}
