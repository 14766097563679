.subtasks {
    .subtasks-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        align-items: center;

        .add-icon {
            cursor: pointer;
            margin-top: 10px;
            padding: 0 3px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #e7e3e3;
            transform: translateY(-10px);
        }

        .add-icon:hover {
            background-color: #bdbbbb;
        }
    }

    .progressive-subtask {
        height: 10px;
        border-radius: 10px;
        background-color: rgb(235, 236, 240);
        margin-bottom: 10px;
        .now {
            background-color: rgb(54, 179, 126);
            height: 100%;
            border-radius: 10px;
        }
    }

    .wrap-task {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        .task {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 0 10px 10px;

            & > div {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
            }
        }
        @media (max-width: 780px) {
            .task {
                display: unset;
                & > div {
                    justify-content: space-between;
                }
            }
        }
        .subtask-shortId {
            font-size: 14px;
            color: #0052cc;
            width: 80px;
            min-width: 80px;
        }

        .point {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: rgba(33, 33, 33, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            color: #313131;
            font-size: 10px;
            margin-right: 6px;
        }
        .subtask-assignee-avatar {
            margin-right: 10px;

            img {
                width: 30px;
                height: 30px;
                border-radius: 30px;
                object-fit: cover;
            }
        }

        .subtask-status {
            padding: 0 10px;
            border-radius: 5px;

            .select-status {
                background-color: transparent;
                border: none;
                text-transform: capitalize;
                option {
                    text-transform: capitalize;
                }
            }
        }
        .expand-task {
            cursor: pointer;
            transition: all, 0.2s;
        }

        .expand-task.expanded {
            transform: rotate(180deg);
        }

        .subtask-title {
            max-width: 300px;
            width: 100%;
            border-radius: 4px;
            &:hover {
                background-color: #eee;
            }
        }
    }

    .bold {
        font-weight: bold;
    }
}
