.working-time {
    margin-top: 50px;

    .filter {
        display: flex;
        flex-direction: row;
        align-items: center;

        .duration-filter,
        .ml-20 {
            margin: 0 12px;
        }
    }
}

.select-user {
    width: 250px;
    margin-left: 12px;
}
.employee-code {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100px;
    padding: 0 12px;
    &:focus {
        outline: none;
    }
}
