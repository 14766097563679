// Manage device Modal
.manage-device-container {
    .MuiDialog-paper{
        overflow: visible;
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span{
            font-size: 26px;
            font-weight: 600;
        }

        .search{
            display: flex;
            justify-content: space-between;
            min-width: 500px;

            .search-select{
                width: 240px;
            }
        }
    }

    .content{
        .table {
            &__header{
                background-color: #f5eded;
                th {
                    font-weight: bold;
                    font-size: 15px;
                }
            }

            &__body{
                cursor: pointer;
                // .name-device{

                // }
                .group_user{
                    .user{
                        display: inline-block;
                        border: 1px solid rgb(216, 213, 213);
                        margin: 0px 5px 5px 0px;
                        padding: 8px 12px;
                        border-radius: 20px;
                    }

                    // .active {
                    //     display: none;
                    // }
                }
            }
        }
    }
}

// Add edit manage device/ device modal
.add-edit-container {
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .content {
        .container{
            margin-top: 20px;
            .row-input{
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .inventory{
                    padding-left: 13px;
                    width: 80%;

                    h6 {
                        font-size: 18px;
                    }
                }
            }

            .response-person {
                margin-bottom: 20px;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}