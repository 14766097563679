.app-details {
    padding-left: 20px;
}

.container-title {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    .add-icon {
        cursor: pointer;
        padding: 0 3px;
        border-radius: 20px;
        background-color: #e7e3e3;
        transform: translateY(-10px);
    }

    .add-icon:hover {
        background-color: #bdbbbb;
    }
}
.container-box {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    .box-item {
        width: 100%;
        display: flex;
        // justify-content: space-around;
        .box-item-column {
            text-align: center;
            width: 100%;
            // height: 100%;
            padding-left: 5px;
            display: flex;
            flex-direction: column;
            .title-info {
                font-size: 14px;
            }
            .content-info {
                font-size: 14px;
                padding-top: 20px;
            }
        }
    }
}
.container-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    padding-bottom: 10px;
}
.button-close {
    background-color: #f50057;
    color: white;
    font-size: 12px;
    border: none;
    border-radius: 2px;
}
.button-create {
    background-color: #3f51b5;
    color: white;
    margin-right: 15px;
    font-size: 12px;
    border: none;
    border-radius: 2px;
}
.content-item-form{
   text-align: center;
   width: 350px;
}

.turn-on-scroll-bar::-webkit-scrollbar {
    display: block;
}
.turn-on-scroll-bar::-webkit-scrollbar {
    width: 10px;
    border-radius: 12px;
}
.turn-on-scroll-bar::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.turn-on-scroll-bar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 12px;
}
.turn-on-scroll-bar::-webkit-scrollbar-thumb:hover {
    background: #555;
}
