.div-container {
    background-color: #f5f5f5 !important;
    position: absolute;
    .back-btn {
        //
        cursor: pointer;
        margin-left: 16px;
        margin-top: 16px;
        border-radius: 50%;
        padding: 8px;
        width: 42px;
        transition: all 0.2s;
        &:hover {
            background-color: #e5e5e5;
            // padding-left: 0;
            padding-right: 16px;
            margin-left: 8px;
            width: 50px;
        }
    }
    @media screen and (max-width: 1300px) {
        position: relative;
    }
}
.rdrDateRangePickerWrapper {
    .rdrDefinedRangesWrapper {
        display: none;
    }
}

.table-container {
    /* width */
    &::-webkit-scrollbar {
        width: 8px;
        display: block !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
