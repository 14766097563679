.main-retention{
    display: flex;
    flex-direction: row; 
    gap: 24px;
   .main-retention-item{
    width: 200px;
    /* height: 100px; */
    border: 1px solid #cdcdcd;
    text-align: center;
    padding: 32px 0;
    border-radius: 8px;
    flex: 1;
    cursor: pointer;
    > div{
        padding: 10px
    }
   }
}