.modal-content {
    .modal-action {
        margin-left: 10px;
        .point,
        .reason {
            width: 95%;
            margin: 10px;
        }
    }
}
.is-loading {
    position: absolute;
    right: 0;
    left: 0;
    height: 100%;
    opacity: 0.6;
    background-color: #000;
    color: #fff;
    font-size: 24px;
    z-index: 1;
    text-align: center;

    p {
        margin-top: 300px;
        font-size: 30px;
        font-weight: 500;
    }
}
