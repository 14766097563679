.posts {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;

    .post {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
}
