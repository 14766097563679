.categories {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 40px 70px 0 70px;

    .category {
        width: 350px;
        h4 {
            padding: 10px;
            box-shadow: 0px 1px #cccccc;
        }
    }
}
