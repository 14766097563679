.dashboard-sidebar {
    position: sticky;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #fff;
    top: 10px;
    align-self: flex-start;

    .add-new-task-btn-container {
        margin: 10px 10px 0 10px;

        button {
            width: 100%;
            margin-bottom: 10px;
        }

        .end-sprint {
            button {
                background-color: rgb(237, 63, 63);
                border-color: rgb(237, 63, 63);
            }
        }
    }

    a {
        font-size: 20px;
        font-weight: 400;
        background-color: #f3f3f3;
        border-radius: 10px;
        margin: 0px 10px 5px 10px;
        padding: 10px;
        cursor: pointer;
        color: #333;
        text-decoration: none;

        &.active {
            text-decoration: underline;
            color: #1976d2;
        }

        &:hover span {
            color: #1976d2;
        }
        p {
            font-size: 16px;
            color: #999999;
        }
    }
}

.sidebar-v2 {
    .end-sprint {
        button {
            background-color: rgb(237, 63, 63) !important;
            border-color: rgb(237, 63, 63);
        }
    }
    .btn-open-create-task-modal {
        background-color: var(--color-7) !important;
        width: 100%;
        box-shadow: none !important;
        font-size: 16px !important;
        font-weight: 500;
        line-height: 24px !important;
        text-transform: capitalize !important;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        margin-bottom: 12px;
    }
    .menu {
        padding: 0 24px;
        a {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            padding: 12px;
            width: 100%;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            text-align: center;
        }
        a:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        }
    }
    .user-info {
        .avatar {
            .avatar-image {
                border-radius: 50%;
                width: 48px;
                height: 48px;
                background-image: linear-gradient(
                    270deg,
                    #4dffdf 0%,
                    #4da1ff 96.35%
                );

                img {
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                }
            }
            .user-level {
                padding: 6px 10px;
                height: fit-content;
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .container-completion-rate {
        padding: 12px;
        .container-content {
            border-top: 1px solid #565680;
            margin-bottom: 20px;
            .avatar {
                margin-top: 8px;
                padding-bottom: 10px;
            }

            .first-name {
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }
            .position,
            .level {
                font-size: 12px;
                color: #ced8e1;
                line-height: 14px;
            }
            .show-goal {
                width: 100%;
                background-color: var(--color-7);
                box-shadow: none;
                font-weight: 400;
                padding: 8px;
                border-radius: 12px;
            }
            .container-group {
                display: flex;
                height: 46px;
                flex-direction: column;
                margin-top: 15px;
                .group-content {
                    height: 46px;
                    display: flex;
                    flex-direction: row;
                    .all-icon {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .container-ratio {
                        padding-left: 12px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .title-content {
                            font-size: 14px;
                            font-weight: 400;
                            color: #ffffff;
                            text-align: left;
                        }
                        .group-text {
                            display: flex;
                            .text-point {
                                width: fit-content;
                                font-size: 24px;
                                font-weight: 700;
                                color: #ffffff;
                                text-align: left;
                            }
                            .group-ratio {
                                text-align: end;
                                width: 100%;
                                &.discipline {
                                    padding-left: 6px;
                                }
                                .point {
                                    color: #5eff5a;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                                .point-done {
                                    color: #4efbe1 !important;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                                .point-discipline {
                                    color: #f06666;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                                // .box-progress {
                                //     width: calc(100% - 0px);
                                //     height: 6px;
                                //     background-color: #25245d;
                                //     border-radius: 100px;
                                //     overflow: hidden;
                                //     display: flex;
                                //     margin-right: 12px;
                                //     .progress-container {
                                //         height: 6px;
                                //         background: linear-gradient(
                                //             270deg,
                                //             #4dffdf 0%,
                                //             #4da1ff 96.35%
                                //         );
                                //         border-radius: 100px;
                                //     }
                                //     .progress-container-add {
                                //         height: 6px;
                                //         width: 10%;
                                //         background: linear-gradient(
                                //             270deg,
                                //             #4dffdf 0%,
                                //             #4da1ff 96.35%
                                //         );
                                //         border-radius: 100px;
                                //         margin-left: 2px;
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
        }
    }
}

.user-experience {
    padding: 16px 0px;
    .level-progress {
        border-radius: 100px;
        width: 100%;
        height: 48px;
        padding: 4px;

        background: linear-gradient(
            270deg,
            rgba(77, 255, 222, 0.2) 0%,
            rgba(77, 160, 255, 0.2) 96.35%
        );

        .level-progress-boundary {
            justify-content: space-between;
            height: 100%;
            overflow: hidden;
            border-radius: 100px;
            position: relative;
        }

        .exp {
            height: 40px;
            border-radius: 50px;
            background: linear-gradient(
                270deg,
                var(--color-6) 0%,
                var(--color-4) 96.35%
            );
            z-index: 0;
            position: absolute;
        }
        .circle-level {
            padding: 4px;
            border-radius: 50%;
            background: linear-gradient(
                270deg,
                var(--color-3) 0%,
                var(--color-4) 96.35%
            );
            z-index: 1;
            .current-level,
            .next-level {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: var(--color-5);
                font-size: 20px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
    }
}
