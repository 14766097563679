.comment-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;

    .content {
        width: 100%;
        margin-left: 8px;
        .content_top {
            background-color: #f9f9f9;
            border-radius: 8px;
            padding: 8px;
            .user_name {
                font-weight: 500;
            }
            .msg {
                font-size: 13px;
            }
            .edit {
                width: 100%;
                display: flex;
                align-items: center;
                textarea {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #ddd;
                    padding: 8px;
                }
            }
        }
        .tools {
            font-size: 12px;
            span {
                padding: 0 8px;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
            .time {
                padding-left: 12px;
            }
        }
    }
}