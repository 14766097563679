.bar-chart {
    height: 400px;
    padding-top: 40px;
    width: 100% !important;

    .recharts-responsive-container {
        width: 100% !important;
    }
    .recharts-layer {
    }

    .custom-tooltip {
        .pro {
            color: #8884d8;
        }
        .ads {
            color: #82ca9d;
        }

        .flutter {
            color: blue;
        }

        .web {
            color: #73a4ff;
        }

        .native {
            color: red;
        }

        .list {
            padding-left: 15px;
        }
        .app-child,
        .transaction-subject {
            padding-left: 25px;
        }
    }
    .recharts-tooltip-wrapper {
        left: 20px !important;

        background-color: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        padding: 12px;
        min-width: 100px;
    }
}
.list-chart-revenue.passemall {
    .bar-chart .recharts-tooltip-wrapper {
        top: unset !important;
        bottom: 0 !important;
    }
}
