:root {
    --background-color-1: #222236;
    --background-color-2: #363658;
    --white-opacity-08: rgba(255, 255, 255, 0.08);
    --color-white: #fff;
    --color-2: #00faff;
    --color-3: #4dffdf;
    --color-4: #4da1ff;
    --color-5: #4dcaf1;
    --color-6: #45ddc2;
    --color-7: #4bb4ed;
}

.background-white-008 {
    background-color: var(--white-opacity-08);
}

.background-color-2 {
    background-color: var(--background-color-2);
}

.min-width {
    min-width: 230px;
}

.radius-12 {
    border-radius: 12px !important;
}

.radius-8 {
    border-radius: 8px !important;
}

.flex {
    display: flex;
}

.padding-12 {
    padding: 12px;
}

.align-item-center {
    display: flex;
    align-items: center;
}

.white {
    color: var(--color-white);
}

.column {
    display: flex;
    flex-direction: column;
}
.color-2 {
    color: var(--color-2);
}
.color-5 {
    color: var(--color-5);
}
.justify-content-center {
    display: flex;
    justify-content: center;
}
