$tooltip-bg: #337ab7;

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.app-main {
    width: 100%;
    background-color: #e5e5e5 !important;

    height: 100%;
    min-height: 100vh;

    .new-color {
        background-color: var(--background-color-1) !important;
        font-family: "Poppins", sans-serif !important;
    }
}
