.zoom-image-modal {
    background: #000000ab;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .modal-image-content {
        margin: auto;
        display: block;
        max-width: 100%;
        max-height: 100vh;
        width: auto;
        height: auto;
        transform: scale(1);
        -webkit-animation-name: zoom;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom;
        animation-duration: 0.6s;
        zoom: 2;
    }

    @-webkit-keyframes zoom {
        from {-webkit-transform:scale(0)} 
        to {-webkit-transform:scale(1)}
    }
      
    @keyframes zoom {
        from {transform:scale(0)} 
        to {transform:scale(1)}
    }

    .close-image-modal {
        position: absolute;
        top: 15px;
        right: 35px;
        color: #f1f1f1;
        font-size: 40px;
        font-weight: bold;
        transition: 0.3s;
        z-index: 10;
    }

    .close-image-modal:hover, .close-image-modal:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }
}