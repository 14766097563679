.reports-statistic {
    .chart-widget {
        padding: 12px;
        border-radius: 12px;
        transition: all 0.2s ease;
        overflow: hidden;
        border: 1px solid rgba(48, 48, 48, 0.2);
        &:hover {
            background-color: #ffffff;
        }
    }
    .paper-container {
        background-color: #f9f9f9;
        border-radius: 12px;
        padding: 16px;

        .user-item {
            border-radius: 12px;
            background: #303030;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            padding: 20px;
            .progress-container {
                flex: 1;
                padding: 0 32px;
                .user-experience {
                    flex: 1;
                    padding: 0;
                }
                .achievement {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 8px;
                    .achievement-item img {
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        margin-left: 8px;
                    }
                }
            }

            .user {
                display: flex;
                .user-item-avatar {
                    .user-avatar {
                        width: 155px;
                        height: 155px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-image: linear-gradient(
                            270deg,
                            #4dffdf 0%,
                            #4da1ff 96.35%
                        );
                        border-radius: 50%;
                        img {
                            width: 145px;
                            height: 145px;
                        }
                    }
                }
                .user-item-info {
                    margin-left: 12px;
                    padding-top: 12px;
                    .username {
                        font-size: 32px;
                        color: #fff;
                        font-weight: bold;
                    }
                    .position {
                        font-size: 18px;
                        color: rgba(255, 255, 255, 0.5);
                    }
                    .level {
                        font-size: 24px;
                        margin-top: 8px;
                        color: #8af3e3;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .area-title {
        color: #fff;
        padding: 16px 32px;
        background: #303030;
        border-radius: 8px;
    }
    .working-chart-area {
    }

    .discipline-chart-area {
        margin-top: 32px;
    }
}
