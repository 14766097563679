.trending_keyword {
    border-top: 1px solid rgba(73, 84, 103, 0.24);
    margin-top: 20px;
    padding-top: 20px;
}

.trending_keyword_chart {
    max-width: 96%;
    margin: auto;
    .header {
        position: relative;
        justify-content: center;
        display: flex;
        margin-bottom: 40px;

        .btn {
            position: absolute;
            top: -5px;
            left: 0;
        }
    }
}