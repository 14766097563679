.event-modal .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .field {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        h5 {
            width: 20%;
            margin: 0;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .form-control {
        width: 70%;
    }
}
