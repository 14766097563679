.btn-date-custom {
    padding: 6px 16px !important;
    background-color: transparent !important;
    color: #495467 !important;
    cursor: pointer !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    align-items: center;
    text-transform: capitalize !important;
}
