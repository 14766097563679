.new-dashboard {
    .tasks-filter {
        margin-left: 10px;
        // margin-right: 10px;
        position: relative;
        flex-grow: 7;
        width: 100%;

        .icon {
            position: absolute;
            width: 27px;
            top: 10px;
            right: 15px;
            cursor: pointer;
        }

        input {
            width: 100%;
            padding: 10px 10px 10px 10px;
            border-radius: 10px;
            border: 2px solid #f3f3f3;
        }
    }
}

.dashboard-jira {
    display: flex;
    margin-top: 50px;

    .dashboard-screen {
        display: flex;
        flex-direction: column;
        width: calc(100% - 250px);

        .dashboard-task {
            display: flex;
            flex-direction: column;
            .alignButton {
                margin-top: -25px;
                margin-left: 40px;
                margin-bottom: 20px;
            }
            .button {
                background-color: #eee;
                &.active,
                &:hover {
                    background-color: rgb(13, 110, 253);
                    color: white;
                }
            }
            .line-1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;

                .list-icons {
                    display: flex;

                    .progress-bar-display {
                        margin-left: 30px;
                        flex-grow: 1;
                        .icon {
                            cursor: pointer;
                        }
                    }

                    .clear-all-filters {
                        flex-grow: 1;
                        margin-left: 10px;
                        .icon {
                            cursor: pointer;
                        }
                    }

                    .filters {
                        flex-grow: 1;
                        margin-left: 10px;
                        .button {
                            border-radius: 12px;
                            .icon {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .line-2 {
                display: flex;
                justify-content: flex-start;
            }

            .active-campaign-progress {
                display: none;
            }
        }
    }
}

.dashboard-v2-container {
    // background-color: var(--background-color-1);
    .dashboard-grid {
        display: grid;
        grid-template-columns: 20fr 100fr;
        grid-gap: 16px;
        gap: 16px;

        .grid-main-content {
            .main-top {
                display: grid;
                grid-template-columns: 78fr 22fr;
                grid-gap: 16px;
                gap: 16px;

                .expected {
                    // display: grid;
                    // grid-template-columns: 1fr 1fr;
                    // grid-gap: 16px;
                    // gap: 16px;
                    display: flex;
                    padding: 8px;

                    .entity-expected {
                        padding-bottom: 14px;
                        flex: 1;
                        .label {
                            font-size: 18px;
                            text-transform: capitalize;
                            line-height: 32px;
                            height: 48px;
                            align-items: center;
                            justify-content: center;
                        }
                        .scrollbarStyles {
                            scrollbar-width: thin;
                            scrollbar-color: #d0d0d0 #f9f9f9;
                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                            }
                            &::-webkit-scrollbar-track {
                                border-radius: 7px;
                                background: transparent;
                            }
                            &::-webkit-scrollbar-thumb {
                                border-radius: 7px;
                                background: #8a8a8a;
                                //  &:horizontal {
                                //   margin-right: 10px; /* Padding on the right side */
                                // }
                                &:hover {
                                    background: #8a8a8a;
                                }
                            }
                        }
                        // .expected-progress {
                        //     background-color: #25245d;
                        //     height: 4px;
                        //     border-radius: 4px;
                        //     flex: 1;
                        //     // margin-left: 8px;
                        //     position: relative;
                        //     .current {
                        //         background: linear-gradient(
                        //             270deg,
                        //             #4dffdf 0%,
                        //             #4da1ff 96.35%
                        //         );
                        //         height: 6px;
                        //         transform: translateY(-1px);
                        //         border-radius: 6px;
                        //     }
                        //     .expect-spot {
                        //         padding: 2px;
                        //         border-radius: 8px;
                        //         transform: translateY(-9px);
                        //         width: fit-content;
                        //         background: linear-gradient(
                        //             270deg,
                        //             #4dffdf 0%,
                        //             #4da1ff 96.35%
                        //         );
                        //         // position: absolute;
                        //         div {
                        //             width: 6px;
                        //             height: 6px;
                        //             border-radius: 50%;
                        //             background: #fff;
                        //         }
                        //     }
                        //     > span {
                        //         color: var(--color-2);
                        //         position: absolute;
                        //         top: -22px;
                        //         right: 0;
                        //         font-size: 12px;
                        //     }
                        // }
                        .expected-list {
                            // max-height: 256px;
                            // overflow-y: scroll;
                            padding: 0 8px;
                            // &::-webkit-scrollbar {
                            //     width: 3px;
                            //     display: block !important;
                            // }

                            // /* Track */
                            // &::-webkit-scrollbar-track {
                            //     background: transparent;
                            // }

                            // /* Handle */
                            // &::-webkit-scrollbar-thumb {
                            //     background: rgba(255, 255, 255, 0.52);
                            //     border-radius: 8px;
                            // }

                            // &::-webkit-scrollbar-button {
                            //     height: 56px;
                            // }
                            // /* Handle on hover */
                            // &::-webkit-scrollbar-thumb:hover {
                            //     // background: var(--background-color-1);
                            // }

                            .expected-list-item {
                                margin-top: 16px;
                                & > div {
                                    margin-top: 6px;
                                }
                            }
                            .stop-watcher {
                                .sprint-progress {
                                    justify-content: space-between;
                                    align-items: end;
                                    .sprint-name {
                                        font-size: 12px;
                                        font-weight: bold;
                                        color: var(--color-2);
                                    }
                                }
                                font-size: 18px;
                                // width: 132px;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }

                @media (max-width: 780px) {
                    .expected {
                        display: unset;
                    }
                }
            }

            @media (max-width: 780px) {
                .main-top {
                    display: unset;
                }
            }

            .filter-user-container {
                // margin-top: 16px;
                height: 88px;
                button {
                    color: rgba(255, 255, 255, 0.52);
                    top: 10px;
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }
                .users-filter {
                    img {
                        border: 3px solid var(--white-opacity-08);
                        &.active {
                            border: 3px solid var(--color-2);
                        }
                    }

                    p {
                        margin-bottom: 0;
                        color: var(--color-white);
                    }
                }
            }
            .folter-btn-wrapper {
                transition: all 0.2s;
                display: inline-block;
                cursor: pointer;
                position: relative;
                margin-bottom: 16px;
                width: 100%;
                left: 50%;
                transform: translateX(-50%);
                &.active {
                    background-color: var(--white-opacity-08);
                }
                border: 1px solid rgba(255, 255, 255, 0.12);
            }

            .background-btn-filter {
                background-color: var(--background-color-1);
                .select-filter {
                    transition: all 0.2s;
                    padding: 16px;
                    width: 100%;
                    height: 50px;
                    position: relative;
                    background-color: rgba(255, 255, 255, 0.16);
                    &.active {
                        background-color: rgba(77, 202, 241, 0.2);
                    }
                }
            }

            .main-body {
                display: grid;
                margin-top: 16px;
                grid-template-columns: 8fr 92fr;
                grid-gap: 16px;
                gap: 16px;
                .btn-merge-task{
                    width: 100%;
                    border-radius: 12px;
                    background: var(--white-opacity-08);
                    margin-bottom: 15px;
                      span {
                        background: linear-gradient(
                            270deg,
                            var(--color-3) 0%,
                            var(--color-4) 96.35%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .btn-click-merge-task{
                    width: 100%;
                    border-radius: 12px;
                    background: white;
                    margin-bottom: 15px;
                      span {
                        background: linear-gradient(
                            270deg,
                            var(--color-3) 0%,
                            var(--color-4) 96.35%
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .body-grid-area {
                    border-radius: 12px;
                    background-color: var(--background-color-2);
                }

                .campaigns {
                    position: relative;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 1px;
                    border-radius: 12px;
                    > div {
                        border-radius: 11px;
                    }

                    .show-campaigns-button {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-radius: 12px;
                        text-transform: capitalize !important;
                        font-size: 14px;
                        min-width: 80px;
                        width: 100%;
                    }
                    background: var(--white-opacity-08);
                    &.active-campaigns {
                        background: linear-gradient(
                            270deg,
                            var(--color-3) 0%,
                            var(--color-4) 96.35%
                        );
                        .show-campaigns-button {
                            background-color: rgba(77, 202, 241, 0.2);
                            > span {
                                background: linear-gradient(
                                    270deg,
                                    var(--color-3) 0%,
                                    var(--color-4) 96.35%
                                );
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }
                }
                .wrap-icon-archive {
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
                .projects-filter {
                    margin-top: 16px;
                }
                .tasks {
                    background: transparent;
                    border: none;
                    overflow-x: hidden;
                }
            }
        }
    }
    @media (max-width: 780px) {
        .dashboard-grid {
            display: unset;
        }
    }
}
