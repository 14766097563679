.question-quality-v2 {
    padding: 24px;
    border-top: 1px solid rgba(73, 84, 103, 0.24);
    padding-top: 24px;
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
    .menu {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 8px 0;
        margin-bottom: 24px;
        cursor: pointer;
        background-color: rgba(73, 84, 103, 0.04);
        border-radius: 8px;

        .active {
            // border-top: 2px solid blue;
            color: #3878ed;
            position: relative;

            &:after {
                position: absolute;
                content: "";
                height: 3px;
                bottom: -11px;
                margin: 0 auto;
                left: -10px;
                right: 0;
                width: 120%;
                -o-transition: 0.5s;
                -ms-transition: 0.5s;
                -moz-transition: 0.5s;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                background: #3878ed;
                border-radius: 8px 8px 0px 0px;
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
        }
    }

    .total {
        margin: 0 20px 28px 80px;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: rgba(73, 84, 103, 0.52);

        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #495467;
        }
    }
}
