.list-items {
  right: 0;
  padding-top: 6px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;

  p {
    text-align: center;
    margin-top: 10px;
    font-size: 24px;
    font-weight: 700;

    font-family: var(--font-family);
    color: #373b53;
    font-size: 24px;
    font-weight: 700;
  }

  .add-item {
    text-align: center;
    margin: 20px;
  }

  .cards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .card-item {
      .card-price {
        transform: translateY(-15px);
        font-size: 16px;
      }
      .btn-exchange-point {
        transform: translateY(-15px);
      }
    }
  }
}

.card-item {
  margin: auto;
  width: 240px;
  position: relative;

  .item-image {
    height: 300px !important;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
    margin-top: 20px;
  }

  .button-buy {
    position: absolute;
    transform: translate(26px, -70px);
    display: flex;
    justify-content: center;
    width: 190px;
    height: 55px;
    border-radius: 15px;
    background-color: #fff;
    cursor: pointer;
    .diamond-icon {
      transform: translateY(15px);
      width: 30px;
      height: 30px;
    }
    p {
      margin-left: 5px;
      color: #8536cd;
      font-size: 30px;
    }
  }

  .button-buy:hover {
    background-color: #e3e4ec;
  }

  .card-description {
    position: absolute;

    .quantity {
      background-color: #f4c210;
      border-radius: 10px;
      padding: 0 20px;
      transform: translate(60px, -310px);
      display: flex;
      p:nth-child(1) {
        font-size: 14px;
        color: #000000;
      }
      p:nth-child(2) {
        margin-left: 5px;
        font-size: 14px;
        color: #f93f53;
      }
    }
  }
}

.change-item-modal {
  font-family: var(--font-family);
  display: flex;

  .modal-content {
    border-radius: 30px;
    border: none;
    background: #fff;
  }

  .modal-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: center;
    background-color: #eac953;

    .modal-title {
      font-size: 26px;
      text-transform: uppercase;
      color: white;
      font-weight: normal;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-footer {
    justify-content: center;

    .btn {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      padding: 10px 30px;
      border: none;
    }

    .cancel-btn {
      background: linear-gradient(180deg, #36cff0 0%, #5997f3 100%);
      font-size: 20px;
    }

    .confirm-btn {
      background: linear-gradient(180deg, #4bee79 0%, #29c85f 100%);
      font-size: 20px;
      display: flex;
      .diamond-icon {
        img {
          width: 25px;
          height: 25px;
        }
      }
      .item-price {
        margin-left: 5px;
      }
    }
  }
}

.my-item {
  background: #fff;

  p {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #373b53;
  }

  .user-item {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 30px;

    .user-item-avatar {
      flex: 1;
      text-align: center;

      img {
        width: 145px;
        height: 145px;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .user-item-info {
      flex: 1;
      margin-top: 15px;
      font-family: var(--font-family);
      text-align: start;

      .username {
        font-size: 24px;
        font-weight: 700;
        color: #32495e;
      }

      .email,
      .position,
      .teams {
        font-weight: 400;
        color: #a3a4ac;
      }

      .teams span {
        margin-right: 5px;
      }

      .point {
        text-align: start;
        .data-point {
          display: inline;
          font-size: 24px;
          font-weight: 700;
          color: #3667f6;
        }

        .text-point {
          display: inline;
          font-size: 24px;
          font-weight: 400;
          color: #3667f6;
        }
      }
    }
  }
}
