.box-from-info-recuiment{
    width: 950px;
    height: 1300px;
    padding-bottom: 50px;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    margin: 0% auto;
    .container-from-info-recuiment{
        background-color: #ADD8E6 ;
        padding: 40px;
        width: 100%;
        height: 100%;
        margin: 0% auto;
        border-radius: 3%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        .container-input{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .input{
                width: 75%;
                height: 40px;
            }
            
            .location{
                width: 75%;
                height: 40px;
                background-color: white ;
                border-radius: 5px;
                text-align: left;
                .text-location{
                    padding: 10px;
                }
            }
           
        }
        .container-input-area{
            width: 100%;
            height: 124px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            margin-bottom: 20px;
            .input-area{
                height: 100%;
                width: 75%;
                background-color: white ;
                
            }
        }
        .btn-send{
            background-color: #F4FA58;
            color: black;
            height: 60px;
            width: 200px;
            border-radius: 50px;
            margin-top: 30px;
        }
    }
    .input-type-number{
        width: 75%;
        height: 40px;
        border: none;
        border-color: darkturquoise;
        border-radius: 6px;
        font-size: 16px;
        padding-left: 10px;
      }
      .input-type-number:focus{
        border: 1px solid #1670BE;
        box-shadow: 0 0 10px #1670BE;
        outline-offset: 0px;
        outline: none;
      }
      .h5{
        text-align: center;
      }
    .container-input-language{
        width: 100%;
        height: 130px;
        .box-input-language{
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .input-language{
                width: 75%;
                height: 40px;
            }
        }
    }
}
