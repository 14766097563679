.overview {
  .MuiPaper-root {
    box-shadow: none;

    th {
      .MuiButtonBase-root {
        // flex-direction: initial;
      }
    }
  }

  .MuiTablePagination-root {
    .MuiToolbar-root {
      > * {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .MuiTableFooter-root,
  .MuiTableHead-root {
    .MuiTableCell-root {
      color: #212121;
      font-size: 16px;
    }
  }

  .MuiTableFooter-root {
    background-color: rgb(235 237 240);

    td {
      padding-left: 0 !important;
    }
  }

  .MuiTableBody-root {
    tr {
      cursor: pointer;
    }
  }
}
