.profile {
  display: flex;
  padding-top: 56px;
  font-family: var(--font-family) !important;

  .history-point {
    flex: 3;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      transform: translateX(-20px);
    }
    .history-point-table {
      border: 1px solid #f0f0f0;
      border-radius: 20px;
      background-color: #ffff;
      .title {
        font-size: 16px;
        top: 30px;
        margin: 22px 35px 0px 0px;
        p:nth-child(1) {
          text-align: center !important;
          margin-left: 0px;
        }
        .title-point {
          transform: translateX(-80px);
          text-align: end !important;
        }
        p:nth-child(4) {
          display: none;
        }
      }
    }
    .tab-lists {
      .dot-notification {
        display: none;
      }
    }
  }

  .user-feedback {
    flex: 1;
    .boxes {
      background-color: #fff;
      padding-bottom: 20px;
      border-radius: 20px;
      margin-left: 20px;
      .carousel {
        .carousel-indicators {
          transform: translateY(30px);
        }
        .carousel-indicators [data-bs-target] {
          background-color: #42565c;
        }
        .carousel-control-prev {
          display: none !important;
        }
        .carousel-control-next {
          display: none !important;
        }
      }

      .box-feedback {
        transform: translateX(5px);
        padding-right: 5px;
        .feedback {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          .split {
            display: none;
          }
          .strength {
            width: 290px;
          }
          .weakness {
            width: 290px;
          }
          .target {
            width: 290px;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    .user-info {
      margin: auto;
    }
  }
}

.user-avatar {
  margin: 7px;
  position: relative;

  & > img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
  }

  &.has-size {
    width: initial;
    height: initial;
  }

  .user-teams-icon {
    position: absolute;
    bottom: -7px;
    right: -9px;

    img {
      width: 20px;
    }
  }
}

.btn-mode {
  position: absolute;
  right: 50px;

  button {
    margin: 10px;
  }
}

.btn-change-name {
  position: absolute;
  right: 50px;

  button {
    margin: 10px;
  }
}
