.all-cycles {
    button{
        text-transform: none;
    }
    &__histories{
        h3{
            padding-bottom: 10px;
        }
        padding-top: 24px;

        &__item{
            margin-top: 10px;
            padding: 10px;
            padding-bottom: 0;
            cursor: pointer;

            &:hover{
                background-color: lightgray;
            }

            .item-info {
                display: flex;
                position: relative;

                &__left {
                    width: 64%;

                    .time{
                        font-size: 13px;
                    }
                }

                .delete_btn {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                }
            }
        }
    }
}

.edit-cycles {
    &__title{
        display: flex;
        align-items: center;

        span{
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__btn {
        padding: 30px 0 20px;
        display: flex;
        justify-content: flex-end;
        button {
            text-transform: none !important;
            margin-right: 40px !important;
        }
    }
}