.test-post {
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 50px;

    .edit-post {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 50px;

        h4 {
            text-decoration: underline;
            margin-left: 5px;
            transform: translateY(3px);
        }
    }

    .is-loading {
        position: absolute;
        top: 0;
    }

    .post-title {
        text-align: center;
        margin-bottom: 30px;
    }

    .post-content {
        padding: 40px;
        word-wrap: break-word;

        img {
            max-width: -webkit-fill-available;
            width: auto;
            height: auto;
            display: block;
            margin: auto;
        }
    }
}
