.career-event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    background-color: white;
    border-radius: 20px;
    width: 100%;

    .title {
        font-size: 36px;
        font-weight: 600;
    }

    .event-container {
        margin-top: 20px;
        width: 100%;
        cursor: pointer;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 10px 70px;
        display: flex;
        flex-direction: column;

        .event-date {
            align-self: center;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .event-detail {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .no-event {
        font-size: 16px;
        font-weight: 400;
    }
}
