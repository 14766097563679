.tab-nav {
    display: flex;
    background: #fff;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    .tab-nav-item {
        cursor: pointer;
        padding: 8px 24px;
        position: relative;
        transition: all 0.5s ease;
        p {
            margin: 0;
        }
        &.active:after {
            position: absolute;
            content: "";
            height: 3px;
            margin: -1px auto;
            left: 0;
            right: 0;
            width: 100%;
            background: #3878ed;
            border-radius: 8px 8px 0px 0px;
            transform: matrix(1, 0, 0, -1, 0, 0);
            bottom: -2px;
            -o-transition: 0.5s;
            -ms-transition: 0.5s;
            -moz-transition: 0.5s;
            -webkit-transition: 0.5s;
            transition: 0.5s;
        }
    }
}
