.assignee-list {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;

    .knowledge-sharing-filter {
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;
        gap: 4px;
        // margin-left: 30px;
        // margin-top: 10px;

        &>div {
            margin-right: 10px;
            position: relative;
            text-align: center;

            img {
                width: 44px;
                height: 44px;
                border-radius: 44px;
                object-fit: cover;
                cursor: pointer;
                border: 3px solid white;

                &.active {
                    border: 3px solid #08adff;
                }
            }

            p {

                max-width: 44px;
                margin-top: 5px;
                color: #808191;
                font-size: 14px;
                font-weight: 400;
                font-family: var(--font-family);
            }
        }
    }
}