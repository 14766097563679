.request-shopping-modal {
  font-family: var(--font-family);

  .modal-content {
    border-radius: 30px;
    border: none;
    background: #fff;
  }

  .modal-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: center;
    background-color: #eac953;

    .modal-title {
      font-size: 26px;
      text-transform: uppercase;
      color: white;
      font-weight: normal;
    }
  }

  .modal-body {
    .change-project .form-select {
      border-radius: 20px;
    }
    .change-content .content .form-control {
      border-radius: 20px;
    }
    .change-price .price .form-control {
      border-radius: 20px;
    }
  }

  .modal-footer {
    justify-content: center;

    .btn {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      padding: 10px 30px;
      border: none;
    }

    .cancel-btn {
      background: linear-gradient(180deg, #36cff0 0%, #5997f3 100%);
    }

    .confirm-btn {
      background: linear-gradient(180deg, #4bee79 0%, #29c85f 100%);
    }
  }
}
