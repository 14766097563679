.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
  }

.selected-file,
.download {
    font-size: 20px;
    text-align: center;
    display: block;
    
}

.name-form{
  
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-width: 300px;
    margin-bottom: 20px;
  
}

.convert-tool {
  margin-top: 20px;
}

.text-white {
  min-width: 200px;
  font-size: 20px;
}