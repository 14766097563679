
.main-nps-report{
    .filter-and-summary {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .filter {
            display: flex;
    
            & > div {
                margin-right: 30px;
            }
        }
    
        .summary {
            font-weight: bold;
        }
    }

    .chart {
        margin-bottom: 30px;

        .recharts-tooltip-wrapper {
            background-color: #f1f1f1;
            padding: 5px 15px;
        }
    }

    .notice {
        display: flex;
        margin-bottom: 20px;

        span {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-right: 30px;

            &:before {
                content: "";
                display: block;
                width: 30px;
                height: 5px;
                position: absolute;
                left: 0;
                top: 10px;
                border-radius: 5px;
            }

            &:nth-child(1):before {
                background-color: green;
            }

            &:nth-child(2):before {
                background-color: orange;
            }

            &:nth-child(3):before {
                background-color: red;
            }

            svg {
                font-size: 13px;
            }
        }
    }

    .questions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .question {
            margin-bottom: 15px;
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;

            h5 {
                font-size: 14px;
                margin: 0;
                cursor: pointer;

                svg {
                    margin-left: 5px;
                }
            }

            .ratings {
                display: flex;
                width: 100%;
                height: 5px;
                margin-top: 5px;

                span:nth-child(1) {
                    background-color: green;
                    border-radius: 5px;
                }

                span:nth-child(2) {
                    background-color: orange;
                }

                span:nth-child(3) {
                    background-color: red;
                    border-radius: 5px;
                }
            }
        }
    }

}
