.wiki {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background-color: #fff;
    border-radius: 20px;

    .is-loading {
        position: absolute;
        top: 0;
    }

    h2 {
        text-align: center;
        margin: 40px 0;
    }

    h3 {
        text-align: center;
        margin-bottom: 60px;
    }

    .documentations {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        position: relative;

        .document {
            margin: 20px 25px;
            cursor: pointer;

            .background-active {
                background-color: #4e63bd;
                padding: 10px;
                border-radius: 10px;
                text-align: center;
                box-shadow: 0px 3px 3px #cccccc;
            }

            .background {
                padding: 10px;
                border-radius: 10px;
                text-align: center;
                box-shadow: 0px 3px 3px #cccccc;
            }

            img {
                width: 60%;
            }

            .name {
                text-align: center;
                font-weight: bold;
                margin-top: 10px;
            }
        }
    }
}
