.boxes {
    margin-bottom: 20px;

    .box-feedback {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        width: calc(100% - 30px);
        margin: 10px 10px 20px 10px;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 10px;

        .month {
            flex: 1;
            // margin: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            transform: translateY(15px);
            margin-bottom: 0;

            p:nth-child(1) {
                font-size: 28px;
                font-weight: 700px;
                color: #42565c;
            }

            p:nth-child(2) {
                font-size: 60px;
                font-weight: 700;
                color: #42565c;
                transform: translateY(-15px);
            }
        }

        .feedback {
            word-wrap: break-word;
            background-color: #f2fdff;
            width: 100%;
            margin-right: 5px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            padding: 10px;

            .split {
                background-color: blue;
            }

            .strength {
                & > p {
                    // text-align: center;
                    color: #56d382;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 10px;
                }
            }

            .weakness {
                & > p {
                    // text-align: center;
                    color: #e99674;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 10px;
                }
            }

            .target {
                & > p {
                    // text-align: center;
                    color: #eac953;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 10px;
                }
            }

            .review {
                & > p {
                    color: #ab5ed7;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 10px;
                }
            }

            .opinion {
                & > p {
                    color: #5db0e8;
                    font-size: 20px;
                    font-weight: 700;
                    padding-top: 10px;
                }
            }

            .content {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
}
