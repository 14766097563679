.all-test-case {
    .test-case-btn{
        display: flex;
        justify-content: space-between;
    
        button{
            text-transform: none;
        }
    }
}

.edit-cases {
    &__title{
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        span{
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__cases {
        padding: 0 0 20px 0;

        .row_1 {
            display: flex;
            justify-content: space-between;

            &__title {
                flex: 1;
            }
        }

        .row_2{
            margin: 30px 0;
            display: flex;
            justify-content: space-between;
        
            &__priority {
                width: 48%;
            }
            
            &__section {
                width: 48%;

            }
        }

        .row_3{
            margin: 30px 0;

            &__label{
                margin-bottom: 10px;
                font-weight: 600;
            }
        }
        .row_4{
            margin-bottom: 30px;

            &__label{
                margin-bottom: 10px;
                font-weight: 600;
            }
        }
    }
    &__btn {
        display: flex;
        justify-content: flex-end;

        button {
            text-transform: none !important;
        }
    }
}