.items-page {
    font-family: var(--font-family);
    display: flex;

    .left:nth-child(1) {
        flex: 3;
        margin-top: 40px;
        margin-right: 30px;

        height: 100%;
        .request {
            .history-point-table {
                
                background: #fff;
                border: 2px solid #f0f0f0;
                border-radius: 20px;
                .title {
                    p:nth-child(2) {
                        margin-left: 50px;
                    }
                    p:nth-child(3) {
                        margin-right: 15px;
                    }
                }

                .tab-lists {
                    margin-bottom: 10px;
                    .items {
                        .tab-items {
                            .item-name {
                                .item-avatar {
                                    margin-top: 25px;
                                    margin-left: 45px;
                                }
                                p {
                                    margin-top: 25px;
                                }
                            }
                            .item-point {
                                margin-top: 35px;
                                .sign-point {
                                    display: inline;
                                }
                                .bonus-point {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .right {
        .title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            transform: translateY(20px);
        }
    }
    .right:nth-child(2) {
        
        margin-top: 33px;
        flex: 2;
        height: 100%;
        button {
            display: none;
        }

        .my-item {
            flex: 2;

            .user-item-avatar {
                margin-left: 40px;
                margin-right: 60px;
            }

            .user-item-info {
                margin-right: 150px;
            }
        }
    }
    @media (max-width: 1000px) {
        flex-direction: column;
    }
}
.add-item {
    margin-right: 50px;
    transform: translateY(15px);
    position: absolute;
    right: 0;
}
