.container-all{
    width: 74%;
    height: 100%;
    margin: 0% auto;

.curriculumvitae-page{
    background-color: #ADD8E6 ;
    padding: 40px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    // font-family: Poppins;
   
    .title-page{
        text-align: center;
        font-weight: bold;
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .input-page {
        padding-bottom: 15px;
        
    }
    .text-upload{
        font-weight: bold;
    }
    .container-text-message{
        padding-top: 30px;
        padding-bottom: 30px;
        border: none;
        width: 100%;
        .text-message{
            border: none;
            width: 100%;
            background-color: white;
            border-radius: 6px;
        }
    }
    .btn-send{
        background-color: #F4FA58;
        color: black;
        height: 60px;
        width: 20%;
        border-radius: 50px;
    }
    .container-btn{
        text-align: center;
    }
    .text-or{
        padding-top: 20px;
    }
    .bottom-page{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .box-upload{
        width: 100%;
        height: 150px;
        background-color: white;
        border: 1px dashed black;
        display: flex;
        flex-direction: center;
        align-items: center;
        border-radius: 5px 5px;
        cursor: pointer;
    }
    .icon-cv{
        width: 50px;
        height: 50px;
    }
    .box-icon{
        width: 100%;
        display: flex;
        flex-direction: center;
        align-items: center;
        padding-left: 35%;
    }
    .text-file{
        padding-left: 20px;
    }
    .icon-select{
        width: 42px;
        height: 52px;
        color: blue;
    }
    .icon-phone-call{
        width: 25px;
        height: 25px;
    }
    .link-icon{
        display: flex;
        flex-direction: center;
        align-items: center;
        
    }
    .link-phone{
        padding-left: 15px;
        font-size: 18px;
    }
    .link-email{
        padding-left: 15px;
        font-size: 18px;
    }
    label:hover {
        background-color: gray;
    }
    .container-header{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .content-header{
        width: 86%;
        height: 70px;
        display: flex;
        justify-content: space-between;
    }
    .container-item{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        align-items: center;
        background-color: white;
        display: flex;
        width: 250px;
        height: 100%;
        justify-content: space-around;
        padding-right: 25px;
        padding-left: 10px;
    }
    .container-content{
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
    }
    
    
    .grid-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 665px;
      padding-left: 35px;
    }
      
    .grid-item {
        width:350px;
        height: 310px;
        padding-top: 50px;
    }
    .item{
        border-radius: 10px;
        background-color: #fff;
        width: 100%;
        height: 260px;
        padding: 15px;
        cursor: pointer;
        .title{
            font-size: 24px;
            color: dodgerblue;
        }
        .box-content{
            display: flex;
            justify-content: space-between;
        }
        .container-content-item{
            width: 90%;
        }
        .title-show{
            color: dodgerblue;
        }
        
       .container-text-language{
            display: inline-block;
            border-radius: 20px ;
            background-color: antiquewhite;
            margin: 5px 0px 0px 5px;
            align-items: center;
            .text-language{
                text-align: center;
                margin: 10px;
            }
       }
    }
    .container-show-all{
        padding-top: 50px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 45px;
        .grid{
            width:350px;
            height: 310px;
        }
    }
    .container-body{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        .title{
            font-weight: 600;
        }
        .container-info{
            width: 100%;
            height: 120px;
            background-color: dodgerblue;
            display: flex;
            justify-content: space-around;
            border-radius: 10px;
            align-items: center;
            .box-title{
                padding: 10px;
                width: 250px;
                height: 100%;
                display: flex;
                padding-top: 30px;
                padding-left: 20px;
                .icon{
                    width: 25px;
                    height: 25px;
                    background-color: #fff;
                }
                 .box{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-left: 20px;
                }
                .tilte-content{
                    color: #fff;
                }
                .text-content{
                    color: #fff;
                    font-weight: 800;
                }
               
            }
            
        }
        .container-list{
            padding-top: 40px;
            width: 100%;
            height: 100%;
            .title-ul{
                font-weight: 600;
            }
            .text-li{
                font-size: 20px;
                padding-left: 15px;
                display: flex;
                flex-direction: column;
            }
        }
    }
   .container-silde{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .next-silde{
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-color: #fff;
        text-align: center;
    }
   }
   .box-slide{
        width: 92%;
        height: 100%;
   }
  .input-type-number{
    width: 100%;
    height: 35px;
    border: none;
    border-color: darkturquoise;
    border-radius: 6px;
    font-size: 16px;
    padding-left: 10px;
  }
  .input-type-number:focus{
    border: 1px solid #1670BE;
    box-shadow: 0 0 10px #1670BE;
    outline-offset: 0px;
    outline: none;
  }
  .box-language{
    height: 100px;
    overflow: auto; 
    min-height:100px
  }
}
}