.question-quality {
    margin: 40px 0px 0px;

    .filter {
        border-radius: 10px;
        
        .filter-by-duration {
            margin-bottom: 10px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 20px;
        }

        background-color: white;
        padding: 30px 20px;

        .form-check {
            margin-bottom: 10px;

            label {
                font-size: 15px;
            }
        }

        .filter-by-sections {
            margin-bottom: 30px;
            border-bottom: 1px solid lightgray;
            padding-bottom: 20px;
            padding-top: 10px;
        }

        .filter-by-tests .form-check {
            position: relative;

            .test-score-detail {
                position: absolute;
                display: none;
                font-size: 13px;
                background-color: white;
                border: 1px solid gray;
                z-index: 1;
                padding: 10px 20px;
                width: 200px;
                right: -200px;
                top: 0;
            }

            &:hover {
                color: #3b94ff;

                .test-score-detail {
                    display: block;
                }
            }
        }
    }

    .detail-panel {
        background-color: white;
        padding: 20px;
        border-radius: 10px;

        .test-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .panel {
                width: 45%;
                background-color: lightgray;
                margin-bottom: 15px;
                padding: 10px;

                h3 {
                    font-size: 16px;
                    text-align: center;
                    font-weight: normal;
                }

                div {
                    text-align: center;
                    font-size: 30px;
                }
            }
        }

        .chart {
            margin-bottom: 30px;

            .recharts-tooltip-wrapper {
                background-color: #f1f1f1;
                padding: 5px 15px;
            }
        }

        

        // .filter-and-summary {
        //     margin-bottom: 20px;
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;

        //     .filter {
        //         display: flex;

        //         & > div {
        //             margin-right: 30px;
        //         }
        //     }

        //     .summary {
        //         font-weight: bold;
        //     }
        // }
    }
}

.question-nps-details-modal {
    .rate, .reported-mistake {
        border-bottom: 1px solid lightgray;
        padding-bottom: 10px;

        & > div:first-child {
            display: flex;
            align-items: center;

            .date {
                font-size: 13px;
                margin-right: 10px;
            }

            .report-mistake-reason {
                font-size: 14px;
                font-style: italic;
            }
        }
    }
}