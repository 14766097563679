.sprint-column {
    min-height: 81vh;
    min-width: 235px;
    flex: 1;
    // background-color: #fff;
    background-color: transparent;
    padding-bottom: 10px;
    border-radius: 5px;
    // margin: 10px 10px;
    margin: 0 16px 0 0;
    &.active {
        border: 2px dashed #6c757d;
    }

    .box-status {
        .status {
            text-align: center;
            font-size: 16px;
            box-shadow: 0px 2px 2px #cccccc;
            border-top: 4px solid;
            border-radius: 4px 4px 20px 20px;
        }
    }

    .wrap-task {
        // background: #fff;
        // border-radius: 25px;
        border-radius: 16px;
        margin-top: 16px;
        // box-shadow: 0px 2px 2px #cccccc;
        display: block;
        transform: translate3d(0, 0, 0);

        .task-box {
            .task-content .line .line-2 {
                .title {
                    color: var(--color-white);
                }

                .right {
                    margin-top: 35px;

                    .shortId {
                        display: none;
                    }

                    .wrap-point {
                        display: none;
                    }
                }
            }
            hr {
                display: block;
            }
        }

        .task-btn {
            text-align: center;
        }
    }
}
