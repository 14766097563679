.paginationBtn{
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    a{
        padding: 10px;
        margin: 8px;
        border-radius: 4px;
        border: 1px solid #aba8ab;
        color: #2d2c32;
        cursor: pointer;
        text-decoration: none;

    }
}