.active-campaign {
    border: 2px solid #08adff !important;
}

.campaign-box {
    // font-family: var(--font-family);
    // background-color: #faf9ff;
    background-color: var(--white-opacity-08);
    font-size: 14px;
    border-radius: 25px;
    border: 2px solid var(--white-opacity-08);
    cursor: pointer;
    position: relative;

    .campaign-success {
        // background-color: #b0ebcf;
        background-color: var(--color-6);
        border-radius: 10px 10px 0px 0px;
    }

    .campaign-fail {
        background-color: #ffdede;
        border-radius: 25px 25px 0px 0px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 15px 15px;

        .left {
            display: flex;
            flex-direction: column;
            position: relative;

            .type {
                .campaign-icon {
                    width: 24px;
                }

                transform: translateY(-2px);
                margin-right: 12px;
            }

            .priority {
                color: white;
                font-weight: 700;
                margin-left: 10px;
                position: absolute;
            }
        }

        .title {
            // color: #212121;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
        }

        .right {
            cursor: pointer;
        }

        .assignee {
            img {
                width: 24px;
                height: 24px;
                border-radius: 24px;
                object-fit: cover;
                border: 2px solid white;
                margin-right: 5px;
            }
        }

        .edit-button {
            height: 24px;
            width: 24px;
            // background-color: rgba(33, 33, 33, 0.4);
            opacity: 0.8;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            .edit-button-icon {
                // background: #fefef8;
                // background-color: var(--background-color-1);
                width: 30px;
            }
        }
    }

    .campaign-content {
        padding: 0px 15px 15px 15px;
        word-wrap: break-word;

        .line {
            .line-2 {
                display: flex;
                flex-flow: wrap;
                margin-top: 10px;
                justify-content: space-between;
                align-items: center;

                &-left {
                    display: flex;
                    align-items: center;

                    .assignee {
                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            object-fit: cover;
                            border: 2px solid white;
                        }
                    }

                    .wrap-project {
                        background-color: rgba(8, 173, 255, 0.12);
                        border-radius: 4px;
                        padding: 8px 16px;
                        margin-left: 8px;

                        .project {
                            color: var(--color-5);
                            font-size: 12px;
                        }
                    }
                }
            }

            .line-3 {
                display: flex;
                margin-top: 10px;

                .custom-progress {
                    width: 100%;
                    height: 21px;
                    border-radius: 10px;
                }
            }

            .break-line {
                width: 100%;
                border-bottom: 1px solid rgba(33, 33, 33, 0.12);
                padding-top: 16px;
            }

            .line-4 {
                display: flex;
                padding-top: 16px;
                align-items: center;

                .title {
                    // color: #272728;
                    color: var(--color-white);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    margin-left: 8px;
                }

                .type {
                    .target-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .line-5 {
                display: flex;
                padding-top: 16px;
                align-items: center;

                .campaign-done-percent {
                    font-weight: 700;
                    font-size: 12px;
                    // color: rgba(33, 33, 33, 0.52);
                    color: rgba(255, 255, 255, 0.52);
                    margin-right: 16px;
                }

                .custom-progress {
                    width: 100%;
                    height: 6px;
                    border-radius: 10px;
                }
            }

            .line-6 {
                display: flex;
                padding-top: 16px;
                align-items: center;
                position: relative;

                .calander {
                    width: 40px;
                    height: 40px;
                    border-radius: 4px;
                    box-shadow: 0px 4px 6px rgba(33, 33, 33, 0.04);
                    background-color: #ffffff;
                    text-align: center;

                    .calander-header {
                        height: 5px;
                        border-radius: 4px 4px 0px 0px;
                    }

                    .start-date {
                        background-color: #69e3c1;
                    }

                    .deadline {
                        background-color: #f56376;
                    }

                    .calander-day {
                        font-weight: 400;
                        font-size: 16px;
                        color: #212121;
                    }

                    .calander-month-year {
                        font-weight: 400;
                        font-size: 7px;
                        color: rgba(33, 33, 33);
                    }
                }

                .days-left {
                    position: absolute;
                    z-index: 1;
                    left: 33%;
                    color: #f56376;
                    font-weight: 600;
                    font-size: 14px;

                    .timer-icon {
                        height: 16px;
                        width: 16px;
                        margin: 4px;
                    }
                }

                .left-duration {
                    width: 100%;
                    margin: 0 8px;
                    .left-duration-height {
                        height: 33px;
                    }

                    .percent-progress {
                        background-color: #faf0f2;
                    }
                }
            }
        }
    }

    .footer {
        .campaign-done-percent-wrapper {
            display: flex;
            align-items: center;
            padding: 8px 15px 0px 15px;

            .custom-progress {
                width: 100%;
                height: 21px;
                border-radius: 10px;
            }
        }

        .wrap-icon-footer {
            display: flex;
            justify-content: center;
            padding: 8px;
        }
    }
}
