.users-filter {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    // margin-left: 30px;
    // margin-top: 10px;

    &>div {
        margin-right: 10px;
        position: relative;
        text-align: center;

        img {
            width: 44px;
            height: 44px;
            border-radius: 44px;
            object-fit: cover;
            cursor: pointer;
            border: 3px solid white;
        }

        p {
            text-align: center;
            // margin-top: 5px;
            color: #808191;
            font-size: 14px;
            font-weight: 400;
            // font-family: var(--font-family);
        }

        .nbAssignedTasks {
            font-size: 10px;
            background-color: gray;
            color: white;
            padding: 2px;
            line-height: 10px;
            border-radius: 5px;
            display: inline-block;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}