.container-modal-goals{
    input:disabled ,  select:disabled {
        background: white;
      }
      .css-16xfy0z-control{
        background-color: white;
      }
      .body-modal-goals{
        .item-modals-goals{
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 10px;
        }
      }
}
