.ck-balloon-panel{
    z-index:9999 !important
}

.button-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .description{
        
    }
}

.faq-container {
    border-top: 1px solid lightgray;
    
    .row-item {
        cursor: pointer;
        img {
            max-width: 100%;
        }
        .special {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg:first-child {
                margin-bottom: 10px,
            }
        }
        td {
            color: #07307a
        }
    }
}

.add-edit-faq-container {
    .title {
        display: flex;
        justify-content: space-between;
    }
    .row-input {
        display: flex;
        flex-direction: column;
    }
    .row-appName {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .select-app{
            display: flex;
            align-items: center;

            .text {
                margin-right: 20px;
            }

            .MuiSelect-select {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
    .actions {
        margin-bottom: 20px;
        margin-right: 30px;
    }
}