.user-progress {
    display: flex !important;
    .progress-test,
    .progress-topic {
        flex: 1;
        flex-wrap: wrap;
        .title {
            padding-top: 20px;
            font-weight: 600;
            font-size: 20px;
        }

        .topic,
        .test {
            padding-top: 12px;
        }
    }
}
.user-progress-action {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    button {
        text-transform: capitalize;
        border-radius: 12px;

        color: #fff;
        transition: all 0.3s ease;
        &:hover {
            transform: translateY(-3px);
        }

        &.success {
            background-color: #0d6efd;
            border: 1px solid #0d6efd;
            &:hover {
                background-color: #0d6efd;
                border: 1px solid #0d6efd;
            }
        }
        &.error {
            background-color: #f44336;
            border: 1px solid #f44336;
            &:hover {
                background-color: #f44336;
                border: 1px solid #f44336;
            }
        }
    }
}
