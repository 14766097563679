.editor-widget {
    .description-line-1 {
        display: flex;
        flex-direction: row;

        .edit-description-button {
            margin-left: 10px;
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }

    .edit-description {
        .handle-edit-description-button {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .show-description {
        overflow-x: scroll;
        // width: 610px;
        min-height: 30px;
        word-wrap: break-word;
        display: inline-block;

        .image {
            text-align: center;
        }
    }

    .show-description:hover {
        // width: 610px;
        min-height: 30px;
        word-wrap: break-word;
        background: #ebe9e9;
        border-radius: 5px;
    }

    img {
        max-width: 580px;
        cursor: pointer;
    }
}