.main-reviews{
    .filter-and-summary {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .filter {
            display: flex;
    
            & > div {
                margin-right: 30px;
            }
        }
    
        .summary {
            font-weight: bold;
        }
    }
    .reviews {
        .review {
            background-color: white;
            margin-bottom: 3px;
            padding: 10px;
            border-bottom: 1px solid lightgray;

            .review-header {
                display: flex;
                justify-content: space-between;

                .review-title {
                    font-weight: bold;
                }

                .review-rating {
                    display: flex;
                    align-items: center;

                    span {
                        margin-right: 3px;
                    }
                }

                .review-author, .review-date {
                    font-style: italic;
                    text-align: right;
                    font-size: 13px;
                }
            }

            .review-content {
                margin-top: 10px;
                font-size: 15px;
            }
        }
    }

}
