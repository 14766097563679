.web-app-index {
    .list-chart {
        display: flex;

        .col {
            flex: 1;
            width: 32%;
        }
    }
}
