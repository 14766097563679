.Schedule {
    height: 700px;
    flex-direction: row;
    justify-content: flex-start;
    margin: 60px 400px;
    background-color: #fff;
    border-radius: 30px;
    overflow-y: scroll;
    position: relative;
    
    .add-icon {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
}