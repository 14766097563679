.add-edit-section{
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content {
        overflow: visible;
        .container{
            
            .row1 {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px
            }
            
            .row2{
                
            }
        }
    }

    .actions {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-left: 30px;
        position: relative;

        .delete-btn{
            position: absolute;
            right: 32px;
        }
    }
}