.top-bar {
    height: var(--height-header);
    font-family: var(--font-family);
    width: 100%;
    padding-top: 10px;
    background: #e5e5e5;
    display: flex;
    flex-flow: row nowrap;

    .left {
        // flex: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;

        .high-score {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 20px;
        }

        .logo {
            // flex: 1;
            cursor: pointer;
            transform: translateY(10px);

            img {
                width: 180px;
                height: 80px;
            }
        }

        .rating-done-current-tasks {
            visibility: hidden;
        }

        .credit {
            flex: 2;
            transform: translateY(25px);
            margin-left: 50px;

            .nav-credit {
                height: 45px;
                width: 450px;
                background: #c6cad2;
                box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
                border-radius: 15px;
                position: relative;

                // cursor: pointer;
                .logo-pool-credit {
                    transform: translate3d(-25px, -25px, 0px);
                }

                .sprint-rating {
                    position: absolute;
                    background: rgb(80, 201, 143);
                    border-radius: 15px;
                    height: 100%;
                }

                .sprint-date-active {
                    background: rgb(237, 63, 63);
                    border-radius: 15px;
                    height: 100%;
                }

                .sprint-date-non-active {
                    visibility: hidden;
                    border-radius: 15px;
                    height: 100%;
                }

                .split {
                    transform: translateY(-35px);

                    .split-1 {
                        position: absolute;
                        left: 45px;
                    }

                    .split-2 {
                        position: absolute;
                        left: 90px;
                    }

                    .split-3 {
                        position: absolute;
                        left: 135px;
                    }

                    .split-4 {
                        position: absolute;
                        left: 180px;
                    }

                    .split-5 {
                        position: absolute;
                        left: 225px;
                    }

                    .split-6 {
                        position: absolute;
                        left: 270px;
                    }

                    .split-7 {
                        position: absolute;
                        left: 315px;
                    }

                    .split-8 {
                        position: absolute;
                        left: 360px;
                    }

                    .split-9 {
                        position: absolute;
                        left: 405px;
                    }
                }

                .surplus-total-pool-credit {
                    // display: none;
                    font-size: 16px;
                    color: #999999;
                    transform: translate3d(30px, -70px, 0px);
                }
            }

            // .nav-credit:hover {
            //     .surplus-total-pool-credit {
            //         display: block;
            //     }
            // }
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        margin-left: 20px;
        align-items: center;

        .point {
            flex: 1;
            display: block;
            transform: translateY(20px);

            .point-icon {
                display: inline-block;
                width: 60px;
                height: 60px;
                transform: translate3d(30px, -10px, 0);
            }

            .my-point-now {
                display: inline-block;
                font-size: 30px;
                font-weight: 700;
                background-color: #fff;
                border-radius: 88px;
                padding: 5px 30px;
                color: #ffb320;
            }
        }

        .my-credit {
            flex: 1.5;
            display: block;
            transform: translateY(15px);

            .diamond-icon {
                display: inline-block;
                transform: translateX(30px);
                z-index: 1;
            }

            .credit-point {
                display: inline-block;
                background-color: #fff;
                border-radius: 88px;
                font-weight: 700;

                .my-credit-now {
                    display: inline-block;
                    color: #8536cd;
                    font-size: 30px;
                    margin-left: 40px;
                    transform: translateY(10px);
                }

                .my-credit-use {
                    display: inline-block;
                    background-color: #dfdff9;
                    transform: translateY(-5px);
                    border-radius: 88px;
                    margin-left: 10px;
                    margin-right: 5px;
                    padding: 0px 20px;

                    p {
                        font-size: 20px;
                        color: #9b9b9b;
                        transform: translateY(10px);
                    }
                }
            }
        }

        .bell-notification {
            display: block;
            transform: translateY(10px);
            position: relative;

            .bell {
                cursor: pointer;
                width: 50px;
                height: 50px;
            }

            .warning {
                transform: translate(20px, 15px);
                position: absolute;
                width: 25px;
                height: 25px;
            }
        }

        .user-area {
            display: block;
            transform: translateY(10px);
            z-index: 1;
            .avatar {
                display: inline-block;
                cursor: pointer;
            }

            #composition-menu {
                li {
                    padding: 6px 16px;
                    border-radius: 4px;
                    width: 100%;
                    justify-content: start;
                }
            }
            .icon-logout {
                display: inline-block;
                cursor: pointer;
                margin-left: 20px;
            }
        }

        .dashboard {
            margin-left: auto;
            padding-top: 20px;
            margin-right: 20px;
        }
    }

    .menu-item {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        word-wrap: break-word;
    }

    @media screen and (max-width: 1400px) {
        flex-direction: column;
        justify-content: center;
        padding-top: 40px;
    }
}
