.img_ripple img{
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
    border: 20px;
    cursor: pointer;
    border-radius: 50%;
    display: block;
    display: grid;
    place-items: center;
    animation-name: ripple ;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    object-fit: cover;
}
@keyframes ripple {
    0%{
        box-shadow: 0 0 0 0 #f0f6fc , 0 0 0 0 #f0f6fc;
    }
    50%{
        box-shadow: 0 0 0 5px #c5d9ed , 0 0 0 10px #c5d9ed;
    }
    100%{
        box-shadow: 0 0 0 0 #c5d9ed , 0 0 0 0 #c5d9ed;
    }
}
.map{
    height: 100vh;
    width: 100%;
    margin-top: 30px;
}
.name{
    font-size: 14px;
    font-weight: bold;
    padding-left: 5px;
    padding-top: 5px;
}