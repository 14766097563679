.revenue {
    .total-revenue {
        padding-left: 80px;
    }
    .list-chart-revenue {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
        // border-top: 1px solid rgba(73, 84, 103, 0.24);
        // padding-top: 24px;
        gap: 40px;

        .main-header {
            margin: 0 !important;
        }
        .col {
            flex: 1;

            .header {
                padding-left: 24px;
                padding-bottom: 40px;
                .row-item {
                    display: flex;
                    padding-top: 14px;

                    p {
                        margin: 0;
                    }
                    p:nth-child(1) {
                        flex: 2;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: rgba(73, 84, 103, 0.52);
                    }
                    p:nth-child(2) {
                        flex: 1;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;
                        color: #495467;
                    }

                    .growth {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
