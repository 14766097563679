.edit-knowledge-sharing {
    padding: 0 20px;

    h2 {
        padding-top: 20px;
        text-align: center;
    }

    .main {
        display: flex;
        flex-direction: column;

        .text-field {
            margin-top: 15px;
        }
    }

    .btn-edit {
        display: inline-block;
        border-radius: 20px;
        background-color: #20A605;
        border: none;
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        height: 40px;
        width: 100px;
        transition: all 0.5s;
        cursor: pointer;
        margin-left: 5px;
        box-shadow: 0 5px #999;

        &>span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
        }
    }

    .btn-edit span:after {
        content: '\21D2';
        position: absolute;
        opacity: 0;
        top: 0;
        right: -30px;
        transition: 0.5s;
    }

    .btn-edit:hover span {
        padding-right: 20px;
    }

    .btn-edit:hover span:after {
        opacity: 1;
        right: 0;
    }

    .btn-edit:active {
        background-color: #3e8e41;
        box-shadow: 0 2px #666;
        transform: translateY(5px);
        transition-duration: 0.001s;
    }

    .MuiFormControl-root {
        margin-top: 20px;
    }
}