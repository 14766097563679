.main-active-user {
    .title {
        margin: 10px 0px 20px 80px;
    }
    .active-user-app {
        flex: 1;
    }

    .chart {
        // height: 270px;
        > text {
            color: white;
        }
    }
}
.list-chart {
    display: flex;
}
