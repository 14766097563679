.date-picker-abTest {
    width: 370px;
    
}

.react-datepicker-popper {
  z-index: 2;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  overflow: visible;
}