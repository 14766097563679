.main-dashboard-data-analytic-v2 {
    // margin-top: 24px;

    .chart {
        margin-bottom: 30px;

        .recharts-tooltip-wrapper {
            background-color: #f1f1f1;
            padding: 5px 15px;
        }
    }
    .header-container {
        background: white;
        .header {
            display: flex;
            align-items: center;
            padding: 18px;
            gap: 16px;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #342f51;

            .top-bar {
                width: 100%;
                padding-top: 0px;
                background-color: #fff;

                .left {
                    .logo {
                        display: none;
                    }
                    .rating-done-current-tasks {
                        display: none;
                    }
                    .credit {
                        display: none;
                    }
                }

                .right {
                    .dashboard {
                        visibility: hidden;
                    }
                    .bell-notification {
                        display: none;
                    }
                }
            }
        }
    }
    .content-container {
        padding: 16px;
        padding-top: 40px;
        // background: #ebedf0;
        .nav {
            gap: 16px;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: space-between;
            .nav-left {
                display: flex;
                gap: 16px;
                flex: 8;
                overflow: hidden;
            }
            // .nav-left,
            // .tab-nav,
            // .date {
            //     flex: 1;
            // }
            .certificate-qty {
                cursor: pointer;
                display: flex;
                gap: 12px;
                align-items: center;
                padding: 10px 18px;
                background: rgba(255, 255, 255, 0.52);
                border-radius: 12px;
                width: 200px;
                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #303451;
                }
            }
            .list-apps-select {
                display: flex;
                gap: 16px;
                // flex: 1;
                overflow: auto;
                .app-select {
                    min-width: 150px;
                    display: flex;
                    gap: 8px;
                    padding: 8px;
                    background: rgba(255, 255, 255, 0.52);
                    border-radius: 12px;
                    align-items: center;
                    div {
                        display: flex;
                        gap: 4px;
                    }
                    p {
                        margin: 0;
                    }
                    .close-icon {
                        margin-left: auto;
                        margin-right: 4px;
                        cursor: pointer;
                    }
                }
            }

            .date {
                width: fit-content;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
                .days {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: rgba(73, 84, 103, 0.52);
                    padding: 4px 8px;
                    background: rgba(73, 84, 103, 0.04);
                    border-radius: 4px;
                }
            }
        }

        .content {
            margin: 16px 0;
            padding: 0;
            // display: flex;
            // gap: 16px;
            position: relative;

            .right-content {
                border-radius: 12px;
                height: fit-content;
                min-height: 70vh;
                background-color: #fff;
                margin-bottom: 16px;

                &.-infomation,
                &.-content {
                    border-radius: 12px;
                }
            }
        }
    }
}
.charts-index {
    .charts-index-content {
        display: flex;
        .app-content {
            width: 40%;
            .recharts-wrapper {
                width: 100% !important;
                .recharts-surface {
                    width: 100% !important;
                }
            }
            .app-name-index {
                padding-left: 48px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #495467;
            }

            .recharts-legend-wrapper {
                width: 100% !important;
                font-weight: 400;
                font-size: 12px;
                // color: #495467;
                .recharts-default-legend {
                    transform: translateX(-35%);
                }
            }
        }
    }
    .form-select-keyword {
        display: flex;
    }

    .MuiFormControl-root {
        margin-left: auto !important;
        * {
            border-width: 0 !important;
        }
    }
    g {
        // * {
        //     // transform: translate(0, 0) !important;
        // }
        text {
            transform: rotate(0deg) !important;
            color: rgba(73, 84, 103, 0.52);
            font-weight: 400;
            font-size: 12px;
        }
    }
}
.MuiMenu-root {
    .MuiPopover-paper {
        width: fit-content;
        .MuiList-root {
            max-height: 50vh;
        }
    }
    // box-shadow: 0px 4px 8px rgba(73, 84, 103, 0.12) !important;
    // border-radius: 8px;
}

#left-menu-drop {
    .MuiMenuItem-root {
        padding: 0 !important;
        background-color: none !important;
    }

    .MuiList-root {
        padding: 0 !important;
    }
    .MuiPaper-root {
        margin-top: 16px;
        box-shadow: none !important;
        background-color: transparent !important;
        box-shadow: 0px 2px 8px rgba(73, 84, 103, 0.12) !important;
        border-radius: 12px;
        overflow-y: auto;
    }

    .left-menu {
        border-radius: 12px;
        z-index: 1;
        background-color: #fff;
        top: 50px;
        @media screen and (min-width: 769px) {
        }
        .top-left-menu {
            padding: 14px 16px 14px 24px;
            display: flex;
            gap: 12px;
            .compare {
                flex: 1;
                display: flex;
                align-items: center;
                gap: 10px;
                background: rgba(73, 84, 103, 0.12);
                border-radius: 12px;
                padding: 12px 18px;
                color: #495467;
                &.active {
                    background: rgba(56, 120, 237, 0.24);
                }
                cursor: pointer;
                p {
                    margin: 0;
                }
            }

            .form-search {
                flex: 2;
                width: 100%;
                * {
                    border-width: 0 !important;
                    background-color: rgba(73, 84, 103, 0.04) !important;
                    border-radius: 12px;
                }

                .MuiFormControl-root {
                    width: 100%;
                    padding: 10px 16px;
                    .MuiInput-root {
                        background-color: transparent !important;
                        input {
                            background-color: transparent !important;
                        }
                    }
                }
                .css-1ptx2yq-MuiInputBase-root-MuiInput-root {
                    &::before,
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}
.chart {
    .recharts-responsive-container {
        .recharts-layer {
            line {
                display: none;
            }
            .recharts-cartesian-axis-ticks {
                g {
                    line {
                        display: none;
                    }
                    text {
                        tspan {
                            padding-top: 100px !important;
                            margin-top: 100px !important;
                            top: 100px !important;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 20px;
                            color: rgba(73, 84, 103, 0.52);
                        }
                    }
                }
            }
        }
    }
}
