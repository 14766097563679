.change-password {
    background-color: #fff;
    border-radius: 25px;
    margin-top: 50px;
    display: flex;
    justify-content: center;

    .box-form {
        min-width: 600px;

        h2 {
            padding: 30px 0;
            text-align: center;
        }

        .form {
            display: flex;
            flex-direction: column;

            .text-field {
                margin: 15px;
            }
        }
    }
}
