/* .editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 0px 15px;
    cursor: pointer;
    min-height: 24px;
    max-height: 45px; 
    overflow: auto; 
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 0px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
} */

.problem-title {
    min-height: 24px; 
    max-height: 45px; 
    overflow: auto; 
    max-width: 300px;
}



.problem-table .ant-table-cell {
    max-width: 400px;
}

.problem-title-comp {
    .prob-pri-assignee {
        .problem-priority-icon {
            width: 24px;
            transform: translateY(-10px);
        }
        .problem-assignee-avatar {
            width: 24px;
            height: 24px;
            transform: translateY(-10px);
            border-radius: 24px;
            position: absolute;  
            right: 12px;
        }
    }
}