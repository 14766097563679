.user-template {
  background: #fff;
  // height: 740px;
  width: 840px;
  border-radius: 20px;

  .user-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding-top: 30px;

    .user-item-avatar {
      flex: 1;

      img {
        width: 145px;
        height: 145px;
        top: 4px;
        margin-left: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .user-item-info {
      flex: 2;
      font-family: var(--font-family);

      .username {
        transform: translateY(30px);
        font-size: 40px;
        font-weight: 700;
        color: #32495e;

        .edit-name {
          margin-left: 15px;
          transform: translateY(-5px);
          cursor: pointer;
        }
      }

      .position {
        color: #a8a8a8;
        font-size: 25px;
        transform: translateY(30px);
      }
    }
  }

  .split {
    transform: translateX(40px);
  }

  .user-level {
    display: flex;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;

    .current-level {
      display: block;
      transform: translateY(-10px);

      .text-lv {
        display: inline-block;
        font-size: 30px;
        font-weight: 700;
        color: #435370;
      }

      .lv {
        margin-left: 10px;
        display: inline-block;
        font-size: 50px;
        font-weight: 700;
        color: #435370;
      }
    }

    .level {
      flex: 1;
      text-align: center;
    }

    .level-in-month {
      flex: 4;
      height: 50px;
      width: 100%;
      background: #c6cad2;
      opacity: 0.7;
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 15px;

      .level-by-date {
        background: linear-gradient(180deg, #3d9cf4 0%, #3e6fef 100%);
        height: 50px;
        border-radius: 15px;
      }
    }

    .next-level {
      display: block;
      transform: translateY(-10px);

      .text-lv {
        display: inline-block;
        font-size: 30px;
        font-weight: 700;
        color: #435370;
      }

      .lv {
        display: inline-block;
        font-size: 50px;
        font-weight: 700;
        color: #435370;
        margin-left: 10px;
      }
    }
  }

  .coming-soon {
    transform: translateX(65px);
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 20px;
    color: #999999;
    margin: 0px 80px;
    padding-bottom: 30px;

    span {
      margin: 10px;
    }

    &.email {
      display: flex;
      flex-direction: row;
    }
  }

  .user-point-info {
    background: #e5e5e5;
    height: 255px;
    margin: 30px;
    border-radius: 30px;

    .line-1 {
      .bonus-history-icon {
        position: absolute;
        margin-top: 25px;
        margin-left: 30px;
      }

      .bonus-history {
        margin-left: 126px;
        position: absolute;
        margin-top: 25px;

        .text {
          display: inline-block;

          p {
            color: #8f8f8f;
            font-size: 20px;
          }
        }

        .data-bonus-history {
          color: #435370;
          font-size: 25px;
          transform: translateY(-15px);
        }
      }

      .top3-icon {
        position: absolute;
        margin-top: 25px;
        margin-left: 300px;
      }

      .top3 {
        margin-left: 395px;
        position: absolute;
        margin-top: 25px;

        .text {
          display: inline-block;

          p {
            color: #8f8f8f;
            font-size: 20px;
          }
        }

        .data-top3 {
          font-size: 25px;
          color: #435370;
          transform: translateY(-15px);
        }
      }

      .top1-icon {
        position: absolute;
        margin-top: 25px;
        margin-left: 540px;
      }

      .top1 {
        margin-left: 640px;
        position: absolute;
        margin-top: 25px;

        .text {
          display: inline-block;

          p {
            color: #8f8f8f;
            font-size: 20px;
          }
        }

        .data-top1 {
          font-size: 25px;
          color: #435370;
          transform: translateY(-15px);
        }
      }
    }

    .line-2 {
      .present-icon {
        margin-left: 30px;
        position: absolute;
        margin-top: 144px;
      }

      .present {
        margin-left: 126px;
        position: absolute;
        margin-top: 144px;

        .text {
          display: inline-block;

          p {
            color: #8f8f8f;
            font-size: 20px;
          }
        }

        .data-present {
          font-size: 25px;
          color: #435370;
          transform: translateY(-15px);
        }
      }

      .point-icon {
        position: absolute;
        margin-top: 144px;
        margin-left: 300px;
      }

      .point {
        margin-left: 395px;
        position: absolute;
        margin-top: 144px;

        .text {
          display: inline-block;

          p {
            color: #8f8f8f;
            font-size: 20px;
          }
        }

        .images {
          margin-left: 10px;
          display: inline-block;
        }

        .data-point {
          color: #435370;
          font-size: 50px;
          transform: translateY(-25px);
        }
      }

      .credit-icon {
        position: absolute;
        margin-top: 144px;
        margin-left: 540px;
      }

      .credit {
        margin-left: 640px;
        position: absolute;
        margin-top: 144px;

        .text {
          display: inline-block;

          p {
            color: #8f8f8f;
            font-size: 20px;
          }
        }

        .images {
          margin-left: 10px;
          display: inline-block;
        }

        .data-credit {
          color: #435370;
          font-size: 50px;
          transform: translateY(-25px);
        }
      }
    }
  }
}
