.checkin-monitor {
    margin-top: 80px;
    margin-bottom: 50px;

    .my-calendar-header {
        display: flex;
        justify-content: space-between;
    }

    h2 {
        font-size: 20px;
    }

    .my-calendar {
        .last-update {
            font-style: italic;
            font-size: 13px;
        }

        .react-calendar {
            width: 100% !important;

            .react-calendar__tile {
                height: 130px;
                background-color: #ececec;
                border: 1px solid white;
                position: relative;
                overflow: visible !important;

                .tile-footer {
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;

                    .checkin {
                        display: flex;
                        justify-content: space-between;
                        margin-left: 2px;

                        & > span {
                            font-size: 12px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            color: black;

                            &.late {
                                color: red;
                            }
                        }
                    }

                    .other-requests {
                        display: flex;
                        align-items: flex-end;

                        .avatars {
                            position: relative;
                            margin-bottom: 3px;
                            margin-right: 3px;

                            img {
                                width: 25px;
                                height: 25px;
                                border-radius: 100%;
                            }

                            div {
                                position: absolute;
                                z-index: 100;
                                display: inline-block;
                                background-color: #6c757d;
                                color: white;
                                width: 17px;
                                height: 17px;
                                border-radius: 100%;
                                text-align: center;
                                line-height: 16px;
                                position: absolute;
                                top: -10px;
                                right: -10px;
                                font-size: 10px;
                            }
                        }

                        .details {
                            background-color: white;
                            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
                            position: absolute;
                            padding: 15px;
                            display: none;
                            z-index: 1000;

                            .request {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 250px;

                                &:not(:last-child) {
                                    margin-bottom: 15px;
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 100%;
                                    margin-right: 5px;
                                }

                                span {
                                    font-size: 13px;
                                }

                                & > div:nth-child(2) {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &.morning {
                                        background-color: #ffe700;
                                    }

                                    &.forbidden {
                                        background-color: #868686;
                                    }

                                    &.afternoon {
                                        background-color: #00dbff;
                                    }

                                    &.allday {
                                        background-color: #28f1b5;
                                    }

                                    .wfh-icon {
                                        color: #0d6efd;
                                        font-size: 13px;
                                    }
                                    .dayoff-icon {
                                        color: #dc3545;
                                        font-size: 10px;
                                    }
                                }
                            }
                        }

                        &:hover .details {
                            display: block;
                        }
                    }
                }

                abbr {
                    width: 28px;
                    height: 28px;
                }
                &.morning abbr {
                    border-radius: 100%;
                    padding: 6px;
                    display: inline-block;
                    background-color: #ffe700;
                    text-align: center;
                }

                &.afternoon abbr {
                    border-radius: 100%;
                    padding: 6px;
                    display: inline-block;
                    background-color: #00dbff;
                    text-align: center;
                }

                &.allday abbr {
                    border-radius: 100%;
                    padding: 6px;
                    display: inline-block;
                    background-color: #28f1b5;
                    text-align: center;
                }

                & > .wfh-icon {
                    color: #0d6efd;
                    position: absolute;
                    bottom: 38px;
                    right: 30px;
                    font-size: 13px;
                }

                & > .dayoff-icon {
                    color: #dc3545;
                    position: absolute;
                    bottom: 40px;
                    right: 34px;
                    font-size: 10px;
                }

                &.approved {
                    position: relative;

                    abbr:before {
                        content: "";
                        position: absolute;
                        background-color: white;
                        width: 15px;
                        height: 15px;
                        top: 40px;
                        right: 35px;
                        border-radius: 100%;
                    }

                    & > .approved-icon {
                        position: absolute;
                        color: green;
                        top: 40px;
                        right: 35px;
                    }
                }

                &.declined {
                    position: relative;
                    overflow: visible !important;

                    abbr:before {
                        content: "";
                        position: absolute;
                        background-color: white;
                        width: 15px;
                        height: 15px;
                        top: 40px;
                        right: 35px;
                        border-radius: 100%;
                    }

                    & > .declined-icon {
                        position: absolute;
                        color: red;
                        top: 40px;
                        right: 35px;
                    }
                }

                &.react-calendar__month-view__days__day--weekend {
                    color: black;
                }
            }
        }
    }

    .indices {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        .indice {
            text-align: right;
            position: relative;
            display: flex;
            align-items: center;
            color: #9a9a9a;
            font-size: 13px;
            margin-bottom: 5px;
            text-transform: capitalize;
            width: 100px;

            span {
                border-radius: 15px;
                width: 15px;
                height: 15px;
                display: inline-block;
                margin-right: 5px;
            }

            svg {
                margin-right: 5px;
            }

            &.morning span {
                background-color: #ffe700;
            }

            &.forbidden span {
                background-color: #868686;
            }

            &.afternoon span {
                background-color: #00dbff;
            }

            &.allday span {
                background-color: #28f1b5;
            }

            &.wfh svg {
                color: #0d6efd;
            }

            &.dayoff svg {
                color: #dc3545;
                font-size: 10px;
            }
        }
    }
}
