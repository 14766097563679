.row-info{
    cursor: pointer;

    .status{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(255, 255, 255, 0);

        &__select{
            width: 94px;
            text-align: start;
        }

        .priority{
            min-width: 100px;
            text-align: center;
            border-radius: 20px;
            display: inline-block;
            padding: 5px 18px;
        }
    }
    
}

.row-dropdown{
    .case__input__row{
        position: relative;
        margin: 30px 0;

        .info{
            position: absolute;
            top: -10px;
            right: 0;
            display: flex;
        }

        

        &__label{
            margin-bottom: 10px;
            margin-left: -5px;
            font-weight: 600;
        }
    }
}

    