.new-knowledge-sharing {
    padding: 0 210px 0 210px;

    @media screen and (max-width: 1440px) {
        padding: 0 10px 0 24px;
    }

    .MuiListItemText-primary {
        text-align: center;
    }

    .knowledge-sharing-filter {
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;
        // margin-left: 30px;
        // margin-top: 10px;

        &>div {
            margin-right: 10px;
            position: relative;
            text-align: center;

            img {
                width: 44px;
                height: 44px;
                border-radius: 44px;
                object-fit: cover;
                cursor: pointer;
                border: 3px solid white;

            }

            p {
                text-align: center;
                margin-top: 5px;
                color: #808191;
                font-size: 14px;
                font-weight: 400;
                font-family: var(--font-family);
            }
        }
    }



    .btn-edit {
        display: inline-block;
        border-radius: 16px;
        background-color: #20A605;
        border: none;
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        height: 30px;
        width: 70px;
        transition: all 0.5s;
        cursor: pointer;
        margin-left: 5px;
        box-shadow: 0 5px #d3d3d3;

        &>span {
            cursor: pointer;
            display: inline-block;
            position: relative;
            transition: 0.5s;
        }

        &.completed {
            display: none;
        }
    }

    .btn-edit span:after {
        content: '\21D2';
        position: absolute;
        opacity: 0;
        top: 0;
        right: -15px;
        transition: 0.5s;
    }

    .btn-edit:hover span {
        padding-right: 20px;
    }

    .btn-edit:hover span:after {
        opacity: 1;
        right: 0;
    }

    .btn-edit:active {
        background-color: #3e8e41;
        box-shadow: 0 2px #bdbdbd;
        transform: translateY(5px);
        transition-duration: 0.000001s;
    }

    .cluster-btn-create {
        position: fixed;
        top: 230px;
        right: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 1440px) {
            right: 30px;
        }

        .btn-create {
            color: #20A605;
            background-color: #20a60521;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
            border: 3px solid #fff;
            margin-bottom: 8px;
        }

        .btn-create:active {
            transform: translateY(5px);
            transition-duration: 0.001s;
        }

        p {
            margin: 0;
        }
    }

}