.infomation-component {
    padding: 40px 28px;
    width: 100%;

    .table-infomation {
        box-shadow: none;
        border-radius: 8px;

        thead {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #495467;
            background: rgba(73, 84, 103, 0.12);
            border-radius: 8px;

            .app {
                display: flex;
                gap: 8px;
            }
        }
        tbody {
            tr {
                th {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #495467;
                }

                td {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(73, 84, 103, 0.52);
                }
            }
        }
    }
}
.back-top {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 24px 24px 0 24px;
    justify-content: space-between;

    .icon {
        cursor: pointer;
    }

    p {
        margin: 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #495467;
    }
}
