.row-info{
    cursor: pointer;

    .priority{
        border-radius: 20px;
        padding: 8px 4px;
    }
}

.row-dropdown{
    // background-color: rgb(241, 241, 241);
    
    .row_2 {
        margin-top: 10px;

        & > div{
            margin-left: 10px;
        }
    }
    
    .row_3 {
        margin-top: 20px;

        & > div{
            margin-left: 10px;
        }
    }
}

    