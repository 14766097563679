.modal-title .task-parent {
    span {
        margin: 0 10px;
    }
}

.modal-body .edit-task-modal {
    // display: flex;
    // flex-flow: row nowrap;
    // padding: 20px;

    h5 {
        font-size: 16px;
        font-weight: 500;
        margin-top: 0.5em;
    }

    .left {
        margin-right: 50px;
        max-width: 610px;

        .field {
            margin-bottom: 25px;

            h5 {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }

        .description {
            margin-top: 20px;
            margin-bottom: 50px;
            width: 610px;

            .description-line-1 {
                display: flex;
                flex-direction: row;

                .edit-description-button {
                    margin-left: 10px;
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }

            .edit-description {
                .handle-edit-description-button {
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .show-description {
                width: 610px;
                min-height: 30px;
                word-wrap: break-word;
                display: inline-block;

                .image {
                    text-align: center;
                }
            }

            .show-description:hover {
                width: 610px;
                min-height: 30px;
                word-wrap: break-word;
                background: #ebe9e9;
                border-radius: 5px;
            }

            img {
                max-width: 580px;
                cursor: pointer;
            }
        }

        .subtasks {
            margin-top: 20px;
        }

        .histories {
            .history {
                margin-bottom: 30px;
                display: flex;
                align-items: center;

                img {
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                    border-radius: 100%;
                    margin-right: 10px;
                }

                &-title strong {
                    text-transform: capitalize;
                }

                &-time {
                    color: #172b4d;
                    font-size: 13px;
                    margin-left: 10px;
                }

                &-changes {
                    display: flex;
                    align-items: center;

                    & > div {
                        color: #172b4d;
                        font-size: 13px;
                        word-break: break-all;
                    }

                    svg {
                        margin: 0 10px;
                    }
                }
            }
        }

        .comments {
            .add-comment {
                display: flex;
                flex-direction: row;

                .user-avatar {
                    margin-right: 15px;
                    img {
                        width: 35px;
                        height: 35px;
                    }
                }

                textarea {
                    overflow-y: hidden;
                    width: 100%;
                    padding: 8px 12px 9px;
                    border-radius: 3px;
                    border: 1px solid rgb(223, 225, 230);
                    color: rgb(23, 43, 77);
                    background: rgb(244, 245, 247);
                    font-weight: normal;
                    font-size: 14px;
                }
            }
        }
    }

    .right {
        flex: 1;
        margin-right: 20px;

        .field {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            justify-content: space-between;
            align-items: center;

            h5 {
                margin: 0;
            }

            .select-user, .select-project, .select-campaign {
                width: 70%;
                div {
                    white-space: initial;
                }
            }
        }

        .failed-reason {
            .select-failed-reason {
                width: calc(100% - 116px);
            }
        }

        .point {
            position: relative;
            .text-box {
                width: calc(100% - 90px);

                input {
                    width: 100%
                }
            }
            .edit-field-button {
                position: absolute;
                right: 3px;
                top: 3px;
                margin: 0px;

                .close-button,
                .save-button {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 18px
                    }
                }
            }
        }

        .form-select {
            width: 70%;
        }

        .form-control {
            width: 70%;
        }

        .deadline {
            .date {
                margin-left: 70px;
                transform: translateY(5px);
            }
        }

        .followers {
            font-size: 14px;
            font-style: italic;
            text-align: right;

            .follow-btn {
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .test-modal{
            display: flex;
            justify-content: flex-end;

            button {
                text-transform: none;
                text-decoration: underline;
                color:black;
                font-style: italic;
                padding: 0;
            }
        }
    }
}
.tick-saved{
    font-size: 18px;
    display: flex;
    color: black;
}
.tick-saving{
    font-size: 18px;
    display: flex;
    padding-left: 400px;
    color: black;
}