@use "~bootstrap/scss/bootstrap";
@use "./header.scss";
@use "./leaderBoard.scss";
@use "./historyPointTable.scss";
@use "./profile.scss";
@use "./item.scss";
@use "./request.scss";
@use "./login.scss";
@use "./navbar.scss";
@use "./pagination.scss";
@use "./modal.scss";
@use "./listitem.scss";
@use "./infoUser.scss";
@use "./profileScreen.scss";
@use "./myitem.scss";

@font-face {
    src: url("../fonts/Comfortaa-VariableFont_wght.ttf");
    font-family: Comfortaa;
}

@font-face {
    src: url("../fonts/FredokaOne-Regular.ttf");
    font-family: FredokaOne;
}

:root {
    --font-family: "Comfortaa";
    --font-leaderboard: "FredokaOne";
    --background-main-color: #ffa400;
    --width-menu-bar: 80px;
    --height-header: 85px;
    --color: rgba(133, 133, 133, 1);
}

.flex {
    display: flex;
}
