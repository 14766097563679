.scrollbarStyles{
  scrollbar-width: thin;
  scrollbar-color: #d0d0d0 #f9f9f9;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 7px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: #8a8a8a;
    //  &:horizontal {
    //   margin-right: 10px; /* Padding on the right side */
    // }  
    &:hover {
      background: #8a8a8a;
    }
  }
  .container-item{
    width: 100%;
    display: flex;
    border-bottom: 1px solid  #565680;
    cursor: pointer;
   
    .icon-hero{
        width: 50px;
        height: 50px;
        margin-right: 8px;
    }
    .container-content{
        display: flex;
        flex-direction: column;
        .title{
            font-size: 12px;
            color:#FFFFFF;
        }
        .text-time{
            font-size: 10px;
            color: rgba(255, 255, 255, 0.52);
            padding-bottom: 10px;
        }
    }
  }
  .text-none-data{
    text-align: center;
    width: 100%;
    height: 100%;
  }
}