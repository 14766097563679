.dialog-form {
    width: 50%;
    .MuiPaper-root {
        min-width: 600px;
    }
    .title-dialog {
        text-align: center;
    }
    .loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content-container {
        margin-top: 20px;
    }
    .youtube-form-container {
        .MuiFormControl-root {
            margin-top: 20px;
        }
    }
    .MuiDialogContent-root {
        overflow: auto;
        max-height: 600px;
        .list-video {
            display: flex;
            align-items: center;
            .youtube-iframe {
                margin-top: 20px;
                margin-left: 8px;
            }
        }
    }

    .list-action {
    }
    .website {
        margin-top: 20px;
    }
    .paa-form-container {
        .button-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            label {
                input {
                    display: none;
                }
            }
            .name-file {
                margin-left: 20px;
            }
        }

        .second-row {
            display: grid;
            margin-top: 20px;
            grid-template-columns: 1fr;
            gap: 20px;
        }
    }
}
