.test-cycle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid lightgray;

    &__link{
        color: rgb(74 74 243);
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
}

.test-cycle {
    display: flex;

    &__tabs{
        border-right: 1px solid lightgray;
        width: 180px;

        & button {
            padding-top: 20px;
            padding-bottom: 20px;
            text-transform: none !important;
            color: rgb(94, 94, 94);
            font-size: 16px;
            font-weight: 600;
        }
    }

    &__content{
        padding: 16px;
        min-height: 600px;
        width: 1100px;
    }
}