.knowledge-sharing-sidebar {
    background-color: white;
    border-radius: 20px;
    padding: 0;
    position: sticky;
    top: 10px;
    overflow: auto;
    max-height: 86vh;
    height: fit-content;


    .list-week {
        cursor: pointer;

        .name-list {
            display: flex;
            padding: 13px;
            justify-content: center;
            position: sticky;
            z-index: 3;
            top: 0;
            background: #d3d3d3;

            p {
                margin: 0;
            }
        }

        .list-week-item {
            .list-week-item-button {
                background-color: #ececec;
                padding: 10px;
                margin: 10px;
                border-radius: 20px;



                &:hover {
                    background-color: rgb(221, 221, 221);
                }

            }

            .monday-to-sunday {
                .MuiTypography-root {

                    font-size: 14px;

                }
            }
        }

    }
}