.request {
    width: 100%;
    .request-title {
        display: flex;
        div {
            flex: 3;
        }
    }
    .request-content {
        display: flex;
        div {
            flex: 3;
        }
    }
}
