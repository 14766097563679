.my-item-template {
  .my-item-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding-bottom: 20px;

    .error {
      margin-left: 380px;
    }

    .card-my-item {
      width: 200px;
      height: 200px;
      margin-top: 35px;
      margin-left: 12px;
      margin-bottom: 5px;

      .card-description {
        img {
          border-radius: 30px;
          width: 200px;
          height: 230px;
          object-fit: cover;
        }

        .date {
          transform: translate(1px, -230px);
          width: 198px;
          padding-top: 3px;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          background-color: rgba(0, 0, 0, 0.2);
          p {
            color: #fff;
            text-align: center;
            font-size: 20px;
            font-weight: 700px;
          }
        }
      }
    }
  }
}
