.expected-progress {
    background-color: #25245d;
    height: 4px;
    border-radius: 4px;
    flex: 1;
    // margin-left: 8px;
    position: relative;
    .current {
        background: linear-gradient(270deg, #4dffdf 0%, #4da1ff 96.35%);
        height: 6px;
        transform: translateY(-1px);
        border-radius: 6px;
    }
    .expect-spot {
        padding: 2px;
        border-radius: 8px;
        transform: translateY(-9px);
        width: fit-content;
        background: linear-gradient(270deg, #4dffdf 0%, #4da1ff 96.35%);
        // position: absolute;
        div {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
        }
    }
    > span {
        color: var(--color-2);
        position: absolute;
        top: -22px;
        right: 0;
        font-size: 12px;
    }
}
