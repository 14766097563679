.task-box {
    transition: all 0.2s;
    font-size: 14px;
    border-radius: 16px;
    cursor: pointer;

    .task-content {
        padding: 8px;
        word-wrap: break-word;

        .line {
            .line-1 {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;

                .left {
                    display: flex;
                    flex-flow: row wrap;
                    position: relative;

                    .type {
                        .task-icon {
                            width: 20px;
                        }

                        .subtask-icon {
                            width: 20px;
                            height: 20px;
                        }

                        .bug-icon {
                            width: 23px;
                            height: 23px;
                        }

                        transform: translateY(-2px);
                    }

                    .priority {
                        color: white;
                        font-weight: 700;
                        margin-left: 10px;
                        position: absolute;
                        margin-right: 4px;
                    }

                    .point {
                        background-color: gray;
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        font-size: 10px;
                        color: white;
                        border-radius: 100%;
                        text-align: center;
                    }

                    .deadline {
                        color: #f06666;
                    }
                }

                .status-subtask {
                    border-radius: 24px;
                    padding: 4px 8px;
                    height: 24px;
                    line-height: 16px;
                }

                .wrap-project {
                    background-color: var(--color-5);
                    border-radius: 20px;
                    padding: 4px 8px;
                    height: 24px;

                    .project {
                        color: var(--background-color-2);
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            .line-2 {
                display: flex;
                flex-flow: column;
                margin-top: 12px;
                .title {
                    color: #172b4d;
                }

                .right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .assignee {
                        flex: 1;
                        img {
                            $size: 24px;
                            width: $size;
                            height: $size;
                            border-radius: 30px;
                            object-fit: cover;
                        }

                        .child-task-avatar {
                            transform: translateX(-10px);
                        }
                    }

                    .days-in-progress {
                        img {
                            width: 30px;
                        }
                    }

                    .wrap-status-name {
                        padding: 0 10px;
                        border-radius: 15px;
                        font-size: 10px;
                        height: 20px;
                        line-height: 20px;
                        color: white;
                    }

                    .shortId {
                        margin-left: 10px;
                    }

                    .wrap-point {
                        background-color: #fff;
                        border-radius: 20px;
                        margin-left: 20px;
                        padding: 5px 15px;
                        transform: translateY(-5px);
                    }

                    .task-test {
                        margin-left: 10px;
                        position: relative;

                        &__badge {
                            .MuiBadge-anchorOriginTopRight {
                                top: 0;
                                right: 0;
                            }

                            .badge_icon {
                                font-size: 12px;
                            }

                            #badge_icon {
                                width: 145px;
                                background-color: red;
                            }
                        }

                        &__progress {
                            position: absolute;
                            top: 1px;
                            right: 1px;
                            padding: 0;
                        }

                        &__icon {
                            font-size: 20px !important;
                        }
                    }
                }
            }

            .line-3 {
                margin-top: 10px;
                // .progressive-task {
                //
                //     height: 4px;
                //     border-radius: 5px;
                //     background-color: #25245d;

                //     .progress-now {
                //         background: linear-gradient(
                //             270deg,
                //             #4dffdf 0%,
                //             #4da1ff 96.35%
                //         );
                //         height: 6px;
                //         transform: translateY(-1px);
                //         border-radius: 10px;
                //     }
                // }
            }
        }
    }
    hr {
        display: none;
    }
}

.subtasks .wrap-task {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
    .task-box {
        border-radius: 0;
        background-color: transparent;
        .wrap-project {
            display: none;
        }
    }
}
