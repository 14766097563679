.container-table-rating{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    font-family: "Poppins";
    .container-header{
        background-color: goldenrod;
    }
    .container-count-content{
        width: 100%;
        height: 30px;
        text-align: start;
        display: flex;
        justify-content: space-around;
        .group-count{
            width: 140px;
            height: 100%;
            display: flex;
            justify-content: space-around;
        }
    }
    .container-date{
        display: flex;
        width: 700px;
        justify-content: space-around;
        .container-input{
            width: 200px;
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
    }
    .title-stt{
        width: 50px;
        text-align: center;
    }
    .title-rate{
        width: 150px;
    }
    .title-table{
        width: 250px;
        text-align: center;
    }
    .text-rate{
        width: 100px;
        text-align: center;
        .icon{
            width: 30px;
            height: 20px;
            padding-left: 10px;
        }
    }
    .title-type{
        width: 230px;
        text-align: center;
        .text-type{
            padding-left: 10px;
        }
    }
    .title-content{
        width: 600px;
        text-align: center;
    }
    .title-manager{
        width: 300px;
        text-align: center;
    }
    .table-bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: darkslategray;
        .containner-button{
            width: 200px;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .btn-next{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid white;
                text-align: center;
            }
        }
        .select-limit{
            width: 50px;
            height: 30px;
            background-color: white;
        }
        
    }
    .select{
        border: none;
        outline: none;
        background-color: goldenrod;
        cursor: pointer;
    }
    .notification-end-data{
        text-align: center;
    }
}