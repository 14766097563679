.comments-widget {
    .add-comment {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        margin-bottom: 12px;

        .user-avatar {
            margin-right: 15px;
            img {
                width: 35px;
                height: 35px;
            }
        }

        textarea {
            overflow-y: hidden;
            width: 100%;
            padding: 8px 12px 9px;
            border-radius: 3px;
            border: 1px solid rgb(223, 225, 230);
            color: rgb(23, 43, 77);
            background: rgb(244, 245, 247);
            font-weight: normal;
            font-size: 14px;
        }

        .button {
            border-radius: 50px;
            margin-left: 4px;
        }
    }
}