.history-point-table {
  .tab-lists {
    position: relative;
    font-family: var(--font-family);

    .items {
      margin: 0px 15px;

      .tab-items {
        padding: 10px;
        display: flex;
        flex-flow: row nowrap;

        .bonus {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          background: #c0fdc2;
          border-radius: 10px;
          width: 100%;
          height: 100%;
          padding-top: 25px;
          padding-bottom: 25px;

          .bonus-image {
            margin-left: 15px;
          }

          .bonus-text {
            flex: 4;
            margin-left: 20px;
            .bonus-title {
              p {
                font-size: 22px;
                font-weight: 700px;
                color: #07bc21;
              }
            }

            .bonus-reason {
              font-size: 15px;
              color: #a3a3a3;
              word-wrap: break-word;
            }
          }

          .bonus-date {
            flex: 1;
            transform: translateY(15px);
            font-size: 25px;
            font-weight: 700;
            color: #acacac;
          }

          .credit {
            flex: 1;
            display: block;
            transform: translateY(10px);
            .diamond {
              width: 25px;
              height: 25px;
              display: inline-block;
            }

            .bonus-credit {
              transform: translateY(5px);
              margin-left: 5px;
              font-size: 25px;
              font-weight: 700;
              color: #bc4fd7;
              display: inline-block;
            }
          }

          .point {
            flex: 1;
            display: block;
            transform: translateY(10px);
            .energy {
              width: 25px;
              height: 25px;
              display: inline-block;
            }

            .bonus-point {
              transform: translateY(5px);
              margin-left: 5px;
              font-size: 25px;
              font-weight: 700;
              color: #ffb320;
              display: inline-block;
            }
          }
        }

        .punish {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          background: #ffe7e7;
          border-radius: 10px;
          width: 100%;
          padding-top: 25px;
          padding-bottom: 25px;

          .punish-image {
            margin-left: 15px;
          }

          .punish-text {
            flex: 4;
            margin-left: 20px;
            .punish-title {
              p {
                font-size: 22px;
                font-weight: 700px;
                color: #fe2323;
              }
            }

            .punish-reason {
              font-size: 15px;
              color: #a3a3a3;
            }
          }

          .punish-date {
            flex: 2;
            font-size: 25px;
            font-weight: 700;
            color: #acacac;
            transform: translateY(15px);
          }

          .point {
            flex: 1;
            display: block;
            transform: translateY(10px);
            .energy {
              width: 25px;
              height: 25px;
              display: inline-block;
            }

            .punish-point {
              transform: translateY(5px);
              margin-left: 5px;
              font-size: 25px;
              font-weight: 700;
              color: #ffb320;
              display: inline-block;
            }
          }
        }

        .item-action {
          margin-top: 40px;
          margin-left: 20px;
          position: relative;
          cursor: pointer;
          .item-description {
            p {
              position: relative;
              text-align: center;
              transform: translateY(0px);
              cursor: auto;
              font-size: 16px;
              font-weight: 400;
              color: #32495e;
            }
          }
          .action-menu {
            font-family: var(--font-family);
            width: 100px;
            position: absolute;
            right: 10px;
            transform: translateY(-60px);

            .action-delete {
              cursor: pointer;
              border: 1.5px solid #858585;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              background: #fff;
              p {
                color: #858585;
              }
            }
            .action-edit {
              cursor: pointer;
              border: 1.5px solid #858585;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              background: #fff;
              padding-bottom: 5px;
              p {
                color: #858585;
              }
            }
          }
        }

        .item-img {
          height: auto !important;
          width: 70%;
          border-radius: 10px;
          background: linear-gradient(0deg, #fff, #fff);
          object-fit: cover;
        }

        .dot-notification {
          position: absolute;
          right: 15px;
          transform: translateY(-35px);
          background-color: red;
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }
    }
  }
}
