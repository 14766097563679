.histories-widget {
    .history {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        img {
            width: 35px;
            height: 35px;
            object-fit: cover;
            border-radius: 100%;
            margin-right: 10px;
        }

        &-title strong {
            text-transform: capitalize;
        }

        &-time {
            color: #172b4d;
            font-size: 13px;
            margin-left: 10px;
        }

        &-changes {
            display: flex;
            align-items: center;

            & > div {
                color: #172b4d;
                font-size: 13px;
                word-break: break-all;
            }

            svg {
                margin: 0 10px;
            }
        }
    }
}