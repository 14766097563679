.container-app-statistics{
    .table-header{
        background-color: rgb(81, 81, 81);
        width: 100%;
        .container-item{
            display: flex;
            flex-direction: column;
            .select{
                background: rgb(81, 81, 81);
                color: white;
                align-items: center;
            }
        }
        .content-header{
             font-weight: 400;
             text-align: center;
             color: rgb(255, 255, 255);
        }
    }
    .table-body{
        width: 100%;
        background-color: rgb(255, 255, 255);
        .content-body{
            width: 300px;
            text-align: center;
            color: rgb(18, 18, 18);
            .content-body-icon-change{
                color: rgb(18, 18, 18);
                cursor: pointer;
            }
        }
        .content-body-stt{
            width: 50px;
            text-align: center;
            color: rgb(18, 18, 18);
        }
        .content-body-mistake{
            width: 350px;
            text-align: center;
            color: rgb(18, 18, 18);
        }
    }
    .contaiener-filter-options{
        width: 400px;
        border-radius: 10px;
         background-color: rgb(255, 255, 255);

     }
    .filter-date{
        width: 500px;
    }   
    .container-short-data{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
    }
}