.home {
  font-family: var(--font-family);
  margin-top: 30px;

  .box {
    display: flex;
    flex-flow: row wrap;

    .wrap-leaderboard {
      flex: 1;
      background-color: #fff;
      border-radius: 15px;
      height: 665px;
      margin-top: 30px;

      .ribbon {
        text-align: center;
        transform: translateY(-30px);
        .title {
          color: #888888;
        }
      }

      .leaderboard {
        transform: translateY(-40px);
        height: 590px;
        border: 2px solid #f0f0f0;
        border-radius: 15px;
        background-color: #e5e5f3;
        margin: 10px;
      }
    }

    .outoffice-registration-container {
      .navbar-position {
        width: 880px;
      }

      .body {
        background-color: #fff;
        border-radius: 20px;
        width: 870px;
        margin-left: 10px;
        margin-top: 10px;
        padding-bottom: 10px;

        .dashboard {
          .outoffice-registration {
            display: flex;
            width: 100%;
            justify-content: space-around;

            .btn {
              width: calc(50% - 20px);
              border-radius: 20px;
              padding: 15px 0;
              font-size: 18px;
              margin: 10px;

              svg {
                font-size: 20px;
                margin-right: 10px;
              }
            }

            .working-time-manager-btn {
              background-color: #56d382;
              border-color: #56d382;
            }

            .request-shopping-btn {
              background-color: #eac953;
              border-color: #eac953;
            }
          }

          .feedback {
            padding-top: 10px;
            margin-left: 10px;
            .boxes {
              .carousel {
                .carousel-indicators {
                  display: none !important;
                }
                .carousel-control-prev {
                  display: none !important;
                }
                .carousel-control-next {
                  display: none !important;
                }
              }
            }
          }
        }

        .list-item-exchange {
          flex-wrap: wrap;
          width: 860px;
          margin: 10px;
        }

        .split {
          margin-left: 50px;
        }

        .text {
          font-size: 24px;
          text-align: center;
          color: #888888;
          transform: translateY(15px);
        }

        .notifications {
          height: 700px;
          overflow-y: scroll;
          .history-point-table .tab-list {
            margin: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }
}
