.ab-test {
  padding: 24px;
  .form-select {
    width: fit-content !important;
    margin-left: auto !important;
  }
  .ab-test-pass {
    text-align: center;
    margin: 15px 15px 15px 15px;
    padding: 40px 24px;
    border-radius: 8px;
    border: 1px solid gray;
  }
  .ab-test-sort {
    display: flex;
    flex-wrap: wrap;
  }
  .ab-test-content {
    position: relative;
    flex: 0 0 47.6%;
    width: 550px;
    text-align: center;
    margin: 20px;
    padding: 30px 55px;
    padding-bottom: 65px;
    border-radius: 8px;
    border: 1px solid gray;
  }
}
