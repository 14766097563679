.work-schedule-register {
    --width-content: 600px;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;

    .header {
        // font-size: 4rem;
        width: var(--width-content);
        // height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: #0ecaf0;
        margin-left: auto;
        margin-right: auto;
        .modal-title {
            font-size: 24px;
            text-transform: uppercase;
            color: white;
            font-weight: normal;
        }
    }

    .modal-body {
        .react-calendar {
            border: none;
            margin: 0 auto;
            background-color: #fcf9fc;
            width: var(--width-content);
            font-size: 14px;
            position: relative;

            .react-calendar__month-view__weekdays__weekday abbr,
            .react-calendar__month-view__weekdays abbr {
                color: black;
                opacity: 0.3;
                text-decoration: none;
            }

            .react-calendar__tile--now {
                background: none;
                color: #ff1493;
            }

            .modify {
                background-color: #b0c4de;
            }
            .react-calendar__tile {
                border: 0.3px solid #dcdcdc;
                height: 60px;
                width: 60px;

                abbr {
                    width: 38px;
                    height: 38px;
                }

                &.morning abbr {
                    border-radius: 100%;
                    background-color: #ffe700;
                    display: inline-flex;
                    align-content: center;
                    justify-content: center;
                    flex-direction: column;
                }

                &.afternoon abbr {
                    border-radius: 100%;
                    background-color: #00dbff;
                    display: inline-flex;
                    align-content: center;
                    justify-content: center;
                    flex-direction: column;
                }

                &.allday abbr {
                    border-radius: 100%;
                    background-color: #28f1b5;
                    display: inline-flex;
                    align-content: center;
                    justify-content: center;
                    flex-direction: column;
                }

                //     &.morning-onl abbr {
                // 	border-radius: 100%;
                // 	background-color: #fa8072;
                // 	display: inline-flex;
                // 	align-content: center;
                // 	justify-content: center;
                // 	flex-direction: column;
                //     }

                //     &.afternoon-onl abbr {
                // 	border-radius: 100%;
                // 	background-color: #5f9ea0;
                // 	display: inline-flex;
                // 	align-content: center;
                // 	justify-content: center;
                // 	flex-direction: column;
                //     }

                //     &.allday-onl abbr {
                // 	border-radius: 100%;
                // 	background-color: #d8bfd8;
                // 	display: inline-flex;
                // 	align-content: center;
                // 	justify-content: center;
                // 	flex-direction: column;
                //     }
            }

            .react-calendar__tile--active {
                background: #afeeee;
                color: inherit;
            }
        }

        .registration-detail-dialog {
            position: absolute;
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            padding: 10px;
            z-index: 100;
            font-size: 1.1rem;
            width: 170px;

            .form-check {
                margin-bottom: 10px;
            }

            .closeIndices {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 8px;
                right: 9px;
                padding: 4px;
                cursor: pointer;
            }
        }
    }

    .indices {
        width: var(--width-content);
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: space-around;

        & > div {
            display: flex;
            // flex-direction: column;
            // align-items: flex-start;
            justify-content: space-evenly;
            width: 100%;
        }

        .indice {
            text-align: right;
            position: relative;
            display: flex;
            align-items: center;
            //     color: white;
            font-size: 16px;
            margin-bottom: 5px;
            text-transform: capitalize;

            span {
                border-radius: 15px;
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 5px;
            }

            &.allday span {
                background-color: #28f1b5;
            }

            &.morning span {
                background-color: #ffe700;
            }

            &.afternoon span {
                background-color: #00dbff;
            }

            // &.allday-onl span {
            //     background-color: #d8bfd8;
            // }

            // &.morning-onl span {
            //     background-color: #fa8072;
            // }

            // &.afternoon-onl span {
            //     background-color: #5f9ea0;
            // }

            &.forbidden span {
                background-color: #868686;
            }

            &.pending span {
                border: 1px dashed gray;
            }
        }
    }

    .footer {
        width: var(--width-content);
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;

        .btn {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            padding: 10px 30px;
            border: none;
            width: 140px;
            + .btn {
                margin-left: 1rem;
            }
        }

        .cancel-btn {
            background: linear-gradient(180deg, #36cff0 0%, #5997f3 100%);
        }

        .confirm-btn {
            margin-left: 1rem;
            background: linear-gradient(180deg, #4bee79 0%, #29c85f 100%);
        }
    }
}

@media only screen and (max-width: 600px) {
    .work-schedule-register {
        --width-content: 97%;
        margin-top: 5rem;

        .modal-body {
            .react-calendar {
                font-size: 16px;

                .react-calendar__tile {
                    height: 50px;
                    width: 50px;

                    abbr {
                        width: 32px;
                        height: 32px;
                    }
                }
            }

            .registration-detail-dialog {
                font-size: 1rem;
            }
        }

        .indices {
            .indice {
                font-size: 14px;
            }
        }

        .footer {
            .btn {
                padding: 8px 20px;
                font-size: 16px;
            }
        }
    }
}
